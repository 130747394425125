<template>
	<div  class="home">
		
		<div v-show="nologin" class="empty nologin">
			<img class="emptyPic" src="../assets/nologin.png">
			<div class="emptyDes">登录后查看购物车</div>
			<div @click="toLogin" class="tologin">立即登录</div>
		</div>
		
		<div v-show="isEmpty" class="empty">
			<img class="emptyPic" src="../assets/empty.png">
			<div class="emptyDes">还没有挑选喜爱的图书呢 这里空空如也</div>
			<div @click="toHome" class="toChoose">去挑选</div>
		</div>

		<div v-show="!isEmpty" class="car">
			<div class="carHead clearfix">
				<span class="carTit">购物车</span>
				<span @click="changeEidt(true)" v-show="!isEdit" class="edit fr">编辑</span>
				<span @click="changeEidt(false)" v-show="isEdit" class="edit fr">完成</span>
			</div>

			<div class="carContent">
				<div v-for="(item,index) in goodsList" :key="index" class="eachGoods">
					<div @click='item.isSelect=!item.isSelect' class="choose"><img v-show="item.isSelect" src="../assets/chooseIco.png"></div>
					<div class="bookPic">
						<img :src="item.goodsPic">
					</div>
					<div class="goodeMes">
						<div class="goodsName">
							<img v-show="vipType != 0 && item.exchangeCount == 1" class="quanIco" src="../assets/carIco.png">
							<img v-show="vipType != 0 && item.exchangeCount == 2" class="quanIco" src="../assets/carIco2.png">
							<span class="bookName">{{item.goodsName}}</span>
						</div>
						<div class="price">￥{{item.discountPrice > 0 ? item.discountPrice : item.goodsPrice}}
							<span class="originalPrice" v-if="item.discountPrice > 0">￥{{item.goodsPrice}}</span>
						</div>

						<div class="goodsNum">
							 <!--<span v-if="item.discountPrice > 0">x1</span>-->
							<el-input-number v-model="goodsList[index].goodsNum" @change="handleChange" :min="1" :max="1" label="描述文字" v-if="item.discountPrice > 0"></el-input-number>
							<el-input-number v-model="goodsList[index].goodsNum" @change="handleChange" :min="1" :max="10" label="描述文字" v-else></el-input-number>
						</div>

					</div>
				</div>

				<div class="botMes">
					<div @click="selectProduct(isSelectAll)" class="selectAll">
						<div class="choose"><img v-show="isSelectAll" src="../assets/chooseIco.png"></div>
						<span>全选</span>
					</div>

					<div class="botMesR">
						<div v-show="!isEdit">
							<!-- <span class="botDes">合计：</span>
							<span class="botPrice">¥ {{totalPrice}}</span> -->
							<span @click="buyGoods" class="activeBtn">结算</span>
						</div>
						<span @click="removeGoods" v-show="isEdit" class="remove">删除</span>
					</div>
				</div>
			</div>
		</div>

		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	import wx from "weixin-js-sdk";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"isEmpty": true,
				"num0": 1,
				"num1": 2,
				"vipType": '1',
				"shoppingCartArr": [],
				"totalPrice": 0,
				"goodsNum": 0,
				"goodsList": [],
				"isEdit": false,
				"nologin": false,
			}
		},
		created() {
			var that = this;
            that.vipType = localStorage.getItem('vipType');
			// 获取购物车
			that.http.getShopPingCar().then((res) => {

				if(res.data.status == 99){
					that.nologin = true;
					return;
				}
				that.shoppingCartArr = res.data.data;
				if (res.data.data.length == 0) {
					that.isEmpty = true;
				} else {
					that.isEmpty = false;
					let linShiArr = [];
                    for (var i = 0; i < that.shoppingCartArr.length; i++) {

						linShiArr[i] = {
							"goodsName": that.shoppingCartArr[i].paperGoods.goodsName,
							"goodsPrice": that.shoppingCartArr[i].paperGoods.price,
							"goodsPic": that.shoppingCartArr[i].paperGoods.coverImg,
							"isSelect": true,
							"goodsNum": 1,
							"id": that.shoppingCartArr[i].paperGoods.id,
							"carId":that.shoppingCartArr[i].id,
							"discountPrice":that.shoppingCartArr[i].paperGoods.discountPrice,
							"exchangeCount":that.shoppingCartArr[i].paperGoods.exchangeCount,
							"goodsType":that.shoppingCartArr[i].paperGoods.goodsType,
						}


					}
					that.goodsList = linShiArr;

				}

			});
			
		},
		mounted() {

		},
		computed: {
			// 检测是否全选
			isSelectAll: function() {
				//如果goodsList中每一条数据的select都为true,就返回true,否则返回false
				return this.goodsList.every(function(val) {
					return val.isSelect
				})
			},
		},
		methods: {
			changeEidt(status) {
				this.isEdit = status;
			},
			handleChange(value) {
				// console.log(value);
			},
			// 全选与取消全选
			selectProduct(_isSelect) {
				var that = this;
				//遍历goodsList,全部取反
				for (var i = 0; i < that.goodsList.length; i++) {
					that.goodsList[i].isSelect = !_isSelect
				}
			},
			// 去首页
			toHome(){
				this.$router.push({name: "home"})
			},
			// 去结算
			buyGoods(){
				var that = this;

				let idStr = "";
				let numStr ="";
				let goodsType = [];
				for (var i = 0; i < that.goodsList.length; i++) {
					if (that.goodsList[i].isSelect) {
						if (idStr == "") {
							idStr += that.goodsList[i].id;
							numStr += that.goodsList[i].goodsNum;
						} else{
							idStr += "_" + that.goodsList[i].id;
							numStr += "_" +  that.goodsList[i].goodsNum;
						}
                        if(goodsType.indexOf(that.goodsList[i].goodsType) == -1){
                            goodsType.push(that.goodsList[i].goodsType)
                        }
					}
				}

                if (idStr == "") {
                    this.$message({
                        showClose: true,
                        message: '请选中商品',
                        type: 'warning'
                    });

                    return;
                }
				let buyActive = false
                if(goodsType.indexOf('权益') != -1 && goodsType.length > 1){
                    buyActive = true;
                }

                if(buyActive){
                    this.$message({
                        showClose: true,
                        message: '虚拟权益商品不能跟实体书籍一起购买，请分开选购。',
                        type: 'warning'
                    });

                    return;
                }

				this.$router.push({
					path: "/order",
					query: {
						id: idStr,
						num:numStr,
						addressId:"default"
					}
				});
			},
			// 删除购物车
			removeGoods() {
				var that = this;

				for (var i = 0; i < that.goodsList.length; i++) {
					if (that.goodsList[i].isSelect) {
						// 删除购物车商品
						that.http.removeGoods({
							"id": that.goodsList[i].carId
						}).then((res) => {
							// console.log(res);

						})
					}
				}
				
				this.$message({
					showClose: true,
					message: '删除成功',
					type: 'success'
				});
				setTimeout(function() {
					history.go(0)
				}, 2500)
			},
			toLogin(){
				var that = this;
				let state = false; //是否嵌套在小程序里
				wx.miniProgram.getEnv(function (res) {
				if (res.miniprogram == true) {
					state = true;
				}
				});
				if (state == true) {
				console.log("我在小程序中");
				wx.miniProgram.navigateTo({ url: "/pages/login/login" });
				} else {
				console.log("我在网页中");
				sessionStorage.setItem("loginReturn", window.location.href);
				that.$router.push({ name: "login" });
				}
			}
		},



	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		min-height: 100vh;
		padding-bottom: 2.46rem;

		text-align: left;
		
		.nologin{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: calc(100vh - 1.26rem);
			background-color: #fff;
			
			.tologin{
				width: 4.98rem;
				background-image: linear-gradient(-90deg, 
					#d2aa6e 0%, 
					#eacd9f 100%), 
				linear-gradient(
					#ffffff, 
					#ffffff);
				background-blend-mode: normal, 
					normal;
				box-shadow: 0px 0px 18px 0px 
					rgba(129, 93, 54, 0.2), 
					inset 0px 1px 0px 0px 
					#fffcfa;
				font-size: 0.36rem;
				color: #ffffff;
				text-align: center;
				height: 1rem;
				line-height: 1rem;
				border-radius: 100px;
				margin: 0.66rem auto 0px;
			}
		}

		.empty {
			padding-top: 1.42rem;

			.emptyPic {
				width: 3.36rem;
				height: 3.35rem;
				margin: 0px auto;
			}

			.emptyDes {
				text-align: center;
				color: #b5b5b5;
				font-size: 0.32rem;
				line-height: 0.32rem;
				margin-top: 0.34rem;
			}

			.toChoose {
				width: 4.98rem;
				font-size: 0.36rem;
				color: #cc9f6f;
				text-align: center;
				height: 1rem;
				border: 1PX solid #cc9f6f;
				line-height: 1rem;
				border-radius: 100px;
				margin: 0.66rem auto 0px;
			}
		}

		.car {
			.carHead {
				height: 0.86rem;
				line-height: 0.86rem;
				position: relative;
				background-color: #ededed;

				.carTit {
					font-size: 0.34rem;
					color: #151515;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				.edit {
					font-size: 0.3rem;
					color: #151515;
					margin-right: 0.56rem;
				}
			}

			.carContent {

				.eachGoods {
					position: relative;
					border-bottom: 0.1rem solid #ededed;
					height: 1.88rem;
					padding: 0.57rem 0px 0.47rem 0.82rem;
					box-sizing: content-box;

					.choose {
						width: 0.3rem;
						height: 0.3rem;
						position: absolute;
						top: 50%;
						left: 0.24rem;
						transform: translateY(-50%);
						border: 0.02rem solid #c8c0bb;
						border-radius: 100px;
					}

					.bookPic {
						display: inline-block;
						vertical-align: middle;
						width: 1.44rem;
						height: 1.88rem;
					}

					.goodeMes {
						display: inline-block;
						vertical-align: top;
						width: 4.1rem;
						margin-left: 0.55rem;
						position: relative;
						height: 1.88rem;

						.goodsName {
							line-height: 0.38rem;

							.quanIco {
								width: 0.64rem;
								height: 0.29rem;
								display: inline;
								margin-right: 0.1rem;
							}

							.bookName {
								font-size: 0.28rem;
								color: #151515;
								display: inline;
							}

						}

						.price {
							font-size: 0.26rem;
							color: #da5d50;
							position: absolute;
							bottom: 0.2rem;
							left: 0;

							.originalPrice{
								position: absolute;
								left: calc(100% + 0.2rem);
								top: 50%;
								transform: translateY(-50%);
								color: #8b8b8b;
								font-size: 0.26rem;
								margin-right: 0.2rem;
								text-decoration: line-through;
							}
						}

						.goodsNum {
							position: absolute;
							right: 0;
							bottom: 0.1rem;
						}

					}


				}
			}

		}

		.botMes {
			position: fixed;
			bottom: 1.26rem;
			width: 100%;
			height: 0.98rem;
			line-height: 0.98rem;
			border-top: 1PX solid #ededed;
			z-index: 10;

			.selectAll {
				padding-left: 0.24rem;
				background-color: #fff;

				.choose {
					display: inline-block;
					vertical-align: middle;
					width: 0.3rem;
					height: 0.3rem;
					border: 1px solid #c8c0bb;
					border-radius: 100px;
				}

				span {
					display: inline-block;
					vertical-align: middle;
					font-size: 0.26rem;
					color: #151515;
					margin-left: 0.18rem;
				}
			}

			.botMesR {
				position: absolute;
				right: 0.33rem;
				top: 0;

				.botDes {
					font-size: 0.24rem;
					color: #151515;
					display: inline-block;
					vertical-align: middle;
				}

				.botPrice {
					font-size: 0.24rem;
					color: #da5d50;
					margin-right: 0.21rem;
					display: inline-block;
					vertical-align: middle;
				}

				.activeBtn {
					width: 1.46rem;
					height: 0.6rem;
					display: inline-block;
					vertical-align: middle;
					line-height: 0.6rem;
					text-align: center;
					border-radius: 100px;
					font-size: 0.28rem;
					color: #ffffff;
					background-image: linear-gradient(84deg,
						#d8bb91 0%,
						#cb9e6e 100%),
						linear-gradient(#e6e6e6,
						#e6e6e6);
				}

				.remove {
					width: 1.46rem;
					height: 0.6rem;
					display: inline-block;
					vertical-align: middle;
					line-height: 0.6rem;
					text-align: center;
					border-radius: 100px;
					font-size: 0.28rem;
					color: #ffffff;
					background-color: #d17474;
				}
			}
		}

	}
</style>
