<template>
	<div  ref="home" class="home">
		
		<div class="seachCon">
			<input v-model="serarchText" type="text" placeholder="搜索">
			<span @click="returnHome" class="return">取消</span>
		</div>
		<div @click="getSearch" class="toSearchDet">
			<img src="../assets/searchIco.png">
			<span>{{serarchText}}</span>
			<span style="margin-right: 0.3rem;" class="fr">点击搜索</span>
		</div>
		
		<div class="searchResult">
			<div class="srHead">
				
			</div>
			<div class="srBody">
				<div v-show="hasBook" class="noBook">未搜索到更多内容</div>
				<div v-show="!hasBook" @click="tobookDet(item.id)" v-for="(item,index) in searchResult" :key="index" class="eachBook">
					<img class="eachBookPic" :src="item.coverImg">
					<div class="eachBookR">
						<div class="eachBookTit">{{item.goodsName}}</div>
						<div v-show="false" class="eachBookDes">{{item.goodsIntro}}</div>
						<div class="eachBookAuthor"><img src="../assets/authorIco.png"><span>{{item.owner}}</span></div>
					</div>
				</div>
			</div>
		</div>
		
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"serarchText":"",
				"searchResult":[],
				"hasBook":false
			}
		},
		activated() {
			this.$refs.home.scrollTop = this.scroll;
		},
		created() {

		},
		mounted() {

		},
		methods: {
			getSearch() {
				this.http.getSearchCon({
					"goodsNameOrOwner":this.serarchText
				}).then((res) => {
					if (res.data.data != "") {
						this.searchResult = res.data.data
						this.hasBook = false;
					}else{
						this.hasBook = true;
					}
				});
			},
			returnHome(){
				history.go(-1)
			},
			tobookDet(id) {
				this.scroll = this.$refs.home.scrollTop;
				this.$router.push({
					path: "/bookDet",
					query: {
						id: id
					}
				});
			},
		},



	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		min-height: 100vh;
		padding-bottom: 1.46rem;
		text-align: left;
		
		.seachCon{
			padding-left: 0.27rem;
			height: 1rem;
			line-height: 1rem;
			
			input{
				width: 6.24rem;
				height: 0.62rem;
				background-color: #ececec;
				border-radius: 100px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 0.2rem;
				text-indent: 0.2rem;
			}
			
			span{
				font-size: 0.26rem;
				color: #1b1b1b;
			}
		}
		
		.toSearchDet{
			height: 0.8rem;
			line-height: 0.8rem;
			padding-left: 0.27rem;
			border-bottom: 1px solid #ececec;
			border-top: 1px solid #ececec;
			
			img{
				display: inline-block;
				vertical-align: middle;
				width: 0.29rem;
				height: 0.28rem;
				margin-right: 0.2rem;
			}
			span{
				display: inline-block;
				vertical-align: middle;
				font-size: 0.26rem;
				color: #1b1b1b;
			}
		}
		
		.searchResult{
			margin-top: 0.4rem;
			
			.srBody{
				padding: 0px 0.5rem 0px 0.36rem;
				
				.noBook{
					font-size: 0.36rem;
					color: #666666;
					text-align: center;
					padding-top: 2rem;
					letter-spacing: 2px;
				}
				
				.eachBook{
					margin-bottom: 0.45rem;
					
					.eachBookPic{
						width: 1.87rem;
						height: 2.55rem;
						border-radius: 0.04rem;
						display: inline-block;
						vertical-align: middle;
					}
					
					.eachBookR{
						width: 4.34rem;
						height: 2.55rem;
						display: inline-block;
						vertical-align: middle;
						margin-left: 0.38rem;
						position: relative;
						
						.eachBookTit{
							font-size: 0.3rem;
							line-height: 0.4rem;
							color: #b5a18d;
							margin-bottom: 0.24rem;
							overflow: hidden;
							text-overflow:ellipsis;
							white-space: nowrap;
						}
						
						.eachBookDes{
							font-size: 0.24rem;
							color: #4d4d4d;
							line-height: 0.38rem;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 3;
							overflow: hidden;
						}
						
						.eachBookAuthor{
							position: absolute;
							bottom: 0;
							left: 0;
							
							img{
								display: inline-block;
								vertical-align: middle;
								width: 0.2rem;
								height: 0.2rem;
							}
							span{
								display: inline-block;
								vertical-align: middle;
								font-size: 0.2rem;
								color: #4d4d4d;
								margin-left: 0.16rem;
							}
						}
					}
				}
			}
		}
		
	}
</style>
