import { render, staticRenderFns } from "./bookDet.vue?vue&type=template&id=25001ff0&scoped=true"
import script from "./bookDet.vue?vue&type=script&lang=js"
export * from "./bookDet.vue?vue&type=script&lang=js"
import style0 from "./bookDet.vue?vue&type=style&index=0&id=25001ff0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25001ff0",
  null
  
)

export default component.exports