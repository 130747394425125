<template>
	<div  class="home">
		
		<div class="pwTit">设置新密码</div>
		<div class="pwDes">6-16位，至少包含数字、字母和字符中的两类</div>
		
		<div class="inputCon">
			<input v-model="password" class="pwInput" type="text" placeholder="请输入密码">
		</div>
		
		<div @click="changePw" class="pwBtn">提交</div>
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"listArr":[],
				"password": ""
			}
		},
		created() {

		},
		mounted() {
			
		},
		methods: {
			changePw() {
				var that = this;
				
				if (that.pwStandard(that.password)) {
					this.$message({
						showClose: true,
						message: '请输入符合条件的密码',
						type: 'error'
					});
					return;
				}
				
				that.http.changePassword({
					"password":that.password
				}).then((res) => {

					if (res.data.status == 1) {
						this.$message({
							showClose: true,
							message: '修改成功，请重新登录',
							type: 'success'
						});
						setTimeout(function(){
							localStorage.clear();
							that.$router.push({name: "login"})
						},2000)
					}
				})
			},
			// 正则密码
			pwStandard(password){
				if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(password))) {
					return true;
				} else {
					return false;
				}
			}
		},
		
		


	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		min-height: 100vh;
		padding-bottom: 1.46rem;
		text-align: left;
		
		
		.pwTit{
			padding-top: 1.5rem;
			font-size: 0.46rem;
			color: #2b2a2a;
			text-align: center;
		}
		
		.pwDes{
			font-size: 0.3rem;
			color: #2b2a2a;
			text-align: center;
			margin-top: 0.3rem;
			opacity: 0.6;
		}
		
		.inputCon{
			width: 6.57rem;
			height: 0.68rem;
			margin: 1.4rem auto 0.75rem;
			position: relative;
			
			.pwInput{
				display: block;
				height: 100%;
				width: 100%;
				color: #2b2a2a;
				font-size: 0.36rem;
				border-bottom: solid 1px #d7c7ae;
				
			}
		}
		
		
		
		.pwBtn{
			width: 5.84rem;
			height: 0.96rem;
			// background-image: linear-gradient(-90deg, 
			// 	#dcb57c 0%, 
			// 	#e6c79a 52%, 
			// 	#efd9b7 100%), 
			// linear-gradient(
			// 	#ffffff, 
			// 	#ffffff);
			// background-blend-mode: normal, 
			// 	normal;
			background-color: #f1b65c;
			letter-spacing: 2px;
			border-radius: 48px;
			text-align: center;
			font-size: 0.36rem;
			color: #ffffff;
			line-height: 0.96rem;
			margin: 0px auto;
		}
	}
</style>
