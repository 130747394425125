<template>
	<div  class="home">
		<div  v-show="orderMes.status != 2" class="orderMask"></div>
		<div class="address">

			<div class="noAddress" v-show="goodsTypeFlag">
				<img class="ico1" src="../assets/orderIco1.png">
				<span slot="left">充值帐号&nbsp;&nbsp;&nbsp;&nbsp;</span>
				<input style="width: 4rem;vertical-align: inherit;" slot="right" v-model="receiverMobile" placeholder="腾讯产品请输入充值的QQ号"/>
				<img class="ico2" src="../assets/arrow1.png">
			</div>
			<div @click="toAddress" class="noAddress" v-show="!goodsTypeFlag && !hasAddress">
				<img class="ico1" src="../assets/orderIco1.png">
				<span class="pleaseWrite">请填写收货地址</span>
				<img class="ico2" src="../assets/arrow1.png">
			</div>
			<div @click="toAddress" v-show="!goodsTypeFlag && hasAddress" class="hasAddress">
				<img class="ico1" src="../assets/orderIco1.png">
				<span class="pleaseWrite">
					<div>
						<span>{{adressMesArr.receiverName}}</span>
						<span>{{adressMesArr.receiverMobile}}</span>
					</div>
					<div>
						<span>{{adressMesArr.province + adressMesArr.city + adressMesArr.district + adressMesArr.address}}</span>
					</div>
				</span>
				<img class="ico2" src="../assets/arrow1.png">
			</div>
		</div>

		<div class="goods">
			<div v-for="(item,index) in goodsArr" :key="index" class="goodsMes">
				<img class="goodsPic" :src="item.paperGoods.coverImg">
				<div class="goosdMesR">
					<div class="goodsName">
						<!-- <img class="quanIco" src="../assets/carIco.png"> -->
						<img v-show="vipType != 0 && item.paperGoods.exchangeCount == 1" class="quanIco" src="../assets/carIco.png">
						<img v-show="vipType != 0 && item.paperGoods.exchangeCount == 2" class="quanIco" src="../assets/carIco2.png">
						<span class="bookName">{{item.paperGoods.goodsName}}</span>
					</div>
					<div class="price">￥{{item.goodsDiscountPrice > 0 ? item.goodsDiscountPrice : item.goodsPrice}}
						<span class="originalPrice" v-if="item.goodsDiscountPrice > 0">￥{{item.goodsPrice}}</span>
					</div>
					<div class="goodsNum">x{{item.goodsCount}}</div>
				</div>
			</div>
			<div class="goodsBot" v-if="vipType != 0">合计：共需要{{totalNum}}张兑书卡</div>
		</div>

		<div class="myBookCards" v-if="vipType != 0">
			<div v-show="!hasCard" class="hasCard" style="opacity: 0.4;">
				<img class="ico3" src="../assets/orderIco2.png">
				<span>我的兑书卡（{{bookCardNum}}张）</span>
				<div class="noCardDes">
					兑书卡不足
					<!-- <img src="../assets/orderIco4.png"> -->
				</div>

			</div>
			<div v-show="hasCard" class="hasCard">
				<img class="ico3" src="../assets/orderIco2.png">
				<span>我的兑书卡（{{bookCardNum}}张）</span>
				<div @click="useBookCard = !useBookCard" class="ico4">
					<img v-show="useBookCard" src="../assets/orderIco4.png">
				</div>

			</div>
		</div>

		<div class="goodsDet">
			<div class="eachDet clearfix" v-if="totalPrice > 0">
				<span class="fl">商品金额</span>
				<span class="fr">¥ {{totalPrice}}</span>
			</div>
			<div class="eachDet clearfix" v-if="totalPrice > 0 && userDiscount != 10">
				<span class="fl">享{{userDiscount}}折优惠</span>
				<span class="fr red">- ¥ {{discount}}</span>
			</div>
			<div class="eachDet clearfix" v-for="(item,index) in goodsDiscountArr" :key="index">
				<span class="fl">特价书</span>
				<span class="fr">¥ {{item.goodsDiscountPrice}}</span>
			</div>
		</div>

		<div class="orderBot">
			<div v-show="isVip" class="toVip"><img src="../assets/orderIco3.png"></div>
			<div class="orderTotal clearfix">
				<div class="fl">
					<span>实付：</span>
					<span class="red">¥ {{finalPrice}}</span>
				</div>

				<div v-show="orderMes.status == 2" @click="takeOrder" class="fr">结算</div>
			</div>
		</div>

		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	import wx from "weixin-js-sdk";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"hasAddress": true,
				"hasCard": false,
                "adressMesArr": {
                    "province" : null,
                    "city" : null,
                    "district" : null,
                    "address" : null,
                    "receiverName" : null,
                    "receiverMobile" : null,
                    "zip" : null,
                },
				"goodsArr": [],
				"goodsDiscountArr": [],
                "goodsDiscountPrice": 0,
				"goodsNumArr": "",
				"totalNum": 0,
				"bookCardNum": 0,
				"isVip": false,
				"useBookCard": true,
				"isWX": false,
				"userMes": [],
				"userDiscount":8.5,
                "vipType":'1',
                "goodsType":[],
                "goodsTypeFlag":false,
                "receiverMobile":"",
				"orderId":"",
				"orderMes":[],
				"idStr":"",
				"numSte":""
			}
		},
		created() {
			// this.idStr = this.$route.query.id;
			// this.numSte = this.$route.query.num;
			
			
		},
		mounted() {
			var that = this;
            that.vipType = localStorage.getItem('vipType');

            // 获取用户信息
            that.http.getUserMes().then((res) => {
                if (res.data.status == 1) {
                    that.userMes = res.data.data;
                    if (that.userMes.paperVip) {
                        that.userDiscount = that.userMes.paperVip.vipDiscount;
                    }
                }else {
                    this.$message({
                        showClose: true,
                        message: '您未登录，请登录后再次操作',
                        type: 'error'
                    });
					that.wxState()
                    return;
                }
            });
            let tengxun = false;

			that.http.getOrderDet({
				"id":that.$route.query.id
			}).then((res) => {
				that.orderMes = res.data.data;
				that.orderId = res.data.data.id;
                that.receiverMobile = res.data.data.receiverMobile;

                // if (res.data.data.address == "") {
				// 	this.hasAddress = false;
				// }

				for (var i = 0 ; i < res.data.data.orderRelation.length; i++) {
                    var relation = res.data.data.orderRelation[i];
                    that.goodsArr.push(relation);
                    that.userDiscount = relation.vipDiscount;
                    // 计算所需兑书卡
                    that.totalNum += parseInt(relation.goodsCount * relation.paperGoods.exchangeCount);
					if (i == 0) {
						that.idStr += relation.paperGoods.id;
						that.numSte += relation.goodsCount;
					} else{
						that.idStr += "_" + relation.paperGoods.id;
						that.numSte += "_" + relation.goodsCount;
					}

                    if(that.goodsType.indexOf(relation.paperGoods.goodsType) == -1){
                        that.goodsType.push(relation.paperGoods.goodsType)
                    }
                    //判断腾讯产品
                    if(!tengxun && relation.paperGoods.goodsName.indexOf('腾讯') != -1){
                        tengxun = true;
                    }

                    //商品折扣率
                    if(!that.userMes.paperVip){
                        that.userDiscount = relation.paperGoods.discount
                    }
					
				}

				// 获取商品信息
				if (that.idStr.toString().indexOf("_") == -1) {
					var goodsIdArr = [that.idStr];
					that.goodsNumArr = [that.numSte];
				} else{
					var goodsIdArr = that.idStr.toString().split("_");
					that.goodsNumArr = that.numSte.toString().split("_");
				}

				// for (var i = 0; i < goodsIdArr.length; i++) {
                //     var num = that.goodsNumArr[i];
                //     that.http.getBookDet({
				// 		"id": goodsIdArr[i]
				// 	}).then((res) => {
				// 		that.goodsArr.push(res.data.data);
                //         // 计算索引
                //         var num = search(goodsIdArr, res.data.data.id);
                //         // 计算所需兑书卡
                //         that.totalNum += parseInt(that.goodsNumArr[num] * (res.data.data.exchangeCount));
				// 	});
				// }


                setTimeout(function () {
                    // 获取兑书卡数量
                    that.http.getBookCardNum().then((res) => {
                        that.bookCardNum = res.data.data.count;
                        if (that.totalNum > that.bookCardNum) {
                            that.hasCard = false;
                            that.useBookCard = false;
                        } else {
                            that.hasCard = true;
                            that.useBookCard = true;
                        }
                    })
                },200)

                setTimeout(function () {
                    //权益产品不需要填收货地址
                    if(that.goodsType.indexOf('权益') != -1){
                        that.goodsTypeFlag = true;
                    }
                    //非腾讯产品自动填充用户手机号
                    if(that.goodsTypeFlag && !tengxun){
                        that.receiverMobile = that.userMes.phone;
                    }
                    if(!that.goodsTypeFlag){
                        // 获取地址
                        if (that.$route.query.addressId == "default") {
                            if (that.orderMes.address == "") {
                                that.http.getAllAddress().then((res) => {
                                    if (res.data.status == 99) {
                                        this.$message({
                                            showClose: true,
                                            message: '您未登录，请登录后再次操作',
                                            type: 'error'
                                        });
										that.wxState()
                                        return;
                                    }
                                    that.adressMesArr = res.data.data[0];
                                })
                            } else{
                                that.adressMesArr = that.orderMes;
                            }

                        } else {
                            that.http.getAllAddress().then((res) => {
                                if (res.data.status == 99) {
                                    this.$message({
                                        showClose: true,
                                        message: '您未登录，请登录后再次操作',
                                        type: 'error'
                                    });
									that.wxState()
                                    return;
                                }
                                for (var i = 0; i < res.data.data.length; i++) {
                                    if (res.data.data[i].id == that.$route.query.addressId) {
                                        that.adressMesArr = res.data.data[i];
                                    }
                                }
                            })
                        }
                    }
                },200)
				
				
			});
			
			let isWeiXin = () => {
				return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
			}
			that.isWX = isWeiXin();
			

			if (localStorage.getItem("userMes").indexOf("金卡") || localStorage.getItem("userMes").indexOf("钻石")) {
				that.isVip = false;
			} else {
				that.isVip = true;
			}

		},
		methods: {
			wxState(){
				let that = this;
				let state = false; //是否嵌套在小程序里
				wx.miniProgram.getEnv(function (res) {
					if (res.miniprogram == true) {
						state = true;
					}
				});
				if (state == true) {
					console.log("我在小程序中");
					wx.miniProgram.navigateTo({ url: "/pages/login/login" });
				} else {
					console.log("我在网页中");
					sessionStorage.setItem("loginReturn", window.location.href);
					that.$router.push({ name: "login" });
				}
			},
			toAddress() {
				sessionStorage.setItem("fromPath", "orderDet");
				sessionStorage.setItem("orderMes", window.location.href.split("?")[1]);
				this.$router.push({
					name: "address"
				})
			},
			takeOrder() {
				var that = this;
				// 下单
				if (that.useBookCard == true) {
					//兑书卡兑换
					
					if (!that.hasAddress) {
						that.$message({
							showClose: true,
							message: '请填写收货地址',
							type: 'error'
						});
						return;
					}

					var goodsData = {
						"buyType": "1",
						"province": that.adressMesArr.province,
						"city": that.adressMesArr.city,
						"district": that.adressMesArr.district,
						"address": that.adressMesArr.address,
						"receiverName": that.adressMesArr.receiverName,
						"receiverMobile": that.adressMesArr.receiverMobile,
						"zip": that.adressMesArr.zip,
						"id":that.$route.query.id
					}
					for (var i = 0 ; i < that.goodsArr.length; i ++) {
						goodsData["relationList[" + i + "].goodsId"] = that.goodsArr[i].paperGoods.id;
						goodsData["relationList[" + i + "].goodsCount"] = that.goodsArr[i].goodsCount;
					}
					
					that.http.takeOrder(goodsData).then((res) => {
						if (res.data.status == 1) {
							that.$message({
								showClose: true,
								message: '支付成功',
								type: 'success'
							});
							setTimeout(function(){
								that.$router.push({name: "mineOrder"})
							},2000)
							
						}
					})


				} else {
					if (!that.hasAddress) {
						that.$message({
							showClose: true,
							message: '请填写收货地址',
							type: 'error'
						});
						return;
					}
					//微信支付
					if (that.isWX) {
						// 公众号支付
						// 请求code
                        var host = window.location.href.split("orderDet")[0];
						var redirectUri = host + "wxPay" +  window.location.search
							+ '&failUrl=' + encodeURIComponent(host + 'orderDet?id=' + that.$route.query.id + '&addressId=' + that.adressMesArr.id);
						// that.http.getWxCode({
						// 	"redirectUri": redirectUri
						// }).then((res) => {
						// 	// window.location.href = res.data.data;
						// 	// alert("http://wxpay.aizhishifm.com/?redirectUrl=" + encodeURIComponent(res.data.data))
						// 	window.location.href = "http://wxpay.aizhishifm.com/?redirectUrl=" + encodeURIComponent(res.data.data);
							
							
						// })
						var header = {
							"paper_token": eval('(' + localStorage.getItem("userMes") + ')').user_token
						}
						window.location.href = "http://wxpay.aizhishifm.com/code.html?reqUrl=" + encodeURIComponent('http://unicom.paper.aizhishifm.com/consumer/paper/user/getCode') + "&backUrl=" + encodeURIComponent(redirectUri) + "&header=" + JSON.stringify(header)
						
					} else{
						// H5支付
						var goodsData = {
							"buyType": "2",
							"province": that.adressMesArr.province,
							"city": that.adressMesArr.city,
							"district": that.adressMesArr.district,
							"address": that.adressMesArr.address,
							"receiverName": that.adressMesArr.receiverName,
							"receiverMobile": that.adressMesArr.receiverMobile,
							"zip": that.adressMesArr.zip,
							"id":that.$route.query.id
						}
						for (var i = 0 ; i < that.goodsArr.length; i ++) {
							goodsData["relationList[" + i + "].goodsId"] = that.goodsArr[i].paperGoods.id;
							goodsData["relationList[" + i + "].goodsCount"] = that.goodsArr[i].goodsCount;
						}
						
						that.http.takeOrder(goodsData).then((res) => {
							var jumpUrl = res.data.data.mwebUrl;
							// alert("http://wxpay.aizhishifm.com/?redirectUrl=" + encodeURIComponent(jumpUrl) + "&backUrl=http%3A%2F%2Funicom.paper.aizhishifm.com%2Fpage%2FmineOrder")
							window.location.href = "http://wxpay.aizhishifm.com/?redirectUrl=" + encodeURIComponent(jumpUrl) + "&backUrl=http%3A%2F%2Funicom.paper.aizhishifm.com%2Fpage%2FmineOrder";
							// window.location.href = jumpUrl;
						})
						
					}
					
					
					

				}




				
			}
		},
		computed: {
			totalPrice() {
				var that = this;
				var totalPrice = 0;
				for (var i = 0; i < that.goodsArr.length; i++) {
                    if(that.goodsArr[i].goodsDiscountPrice == 0){
                        totalPrice += that.goodsArr[i].goodsPrice * that.goodsArr[i].goodsCount;
                    }else {
                        that.goodsDiscountArr.push(that.goodsArr[i]);
                        that.goodsDiscountPrice += that.goodsArr[i].goodsDiscountPrice;
                    }
				}
				return totalPrice;
			},
			discount() {
				return (this.totalPrice * ((10 - this.userDiscount) / 10)).toFixed(2);
			},
			finalPrice() {
				if (this.useBookCard) {
					return 0;
				} else{
					return (this.totalPrice * (this.userDiscount / 10) + this.goodsDiscountPrice).toFixed(2);
				}
				
			}

		}


	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		min-height: 100vh;
		padding-bottom: 3.46rem;
		text-align: left;
		
		.orderMask{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 8;
		}
		
		.address {
			border-bottom: 0.1rem solid #ededed;

			.noAddress {
				height: 1.14rem;
				line-height: 1.14rem;
				position: relative;
				padding-left: 0.43rem;

				.ico1 {
					display: inline-block;
					vertical-align: middle;
					width: 0.24rem;
					height: 0.34rem;
					margin-right: 0.25rem;
				}

				.pleaseWrite {
					font-size: 0.3rem;
					color: #151515;
					display: inline-block;
					vertical-align: middle;


				}

				.ico2 {
					position: absolute;
					top: 50%;
					right: 0.25rem;
					width: 0.14rem;
					height: 0.25rem;
					transform: translateY(-50%);
				}
			}

			.hasAddress {
				position: relative;

				padding: 0.3rem 0rem;
				padding-left: 0.43rem;

				.ico1 {
					display: inline-block;
					vertical-align: middle;
					width: 0.24rem;
					height: 0.34rem;
					margin-right: 0.25rem;
				}

				.pleaseWrite {
					font-size: 0.3rem;
					color: #151515;
					display: inline-block;
					vertical-align: middle;
					width: 5.6rem;
					line-height: 0.38rem;
					font-size: 0.28rem;

					div {
						margin-bottom: 0.1rem;

						span {
							margin-right: 0.2rem;
						}
					}

				}

				.ico2 {
					position: absolute;
					top: 50%;
					right: 0.25rem;
					width: 0.14rem;
					height: 0.25rem;
					transform: translateY(-50%);
				}

			}
		}

		.goods {
			border-bottom: 0.1rem solid #ededed;

			.goodsBot {
				font-size: 0.22rem;
				color: #2c2b2b;
				height: 0.79rem;
				line-height: 0.79rem;
				width: 6.9rem;
				margin: 0.37rem auto 0rem;
				padding: 0px 0.08rem;
				border-top: 1PX solid #ededed;
			}

			.goodsMes {
				padding: 0.43rem 0.39rem 0px;


				.goodsPic {
					display: inline-block;
					vertical-align: middle;
					width: 1.44rem;
					height: 1.88rem;
				}

				.goosdMesR {

					display: inline-block;
					vertical-align: top;
					width: 4.7rem;
					margin-left: 0.55rem;
					position: relative;
					height: 1.88rem;

					.goodsName {
						line-height: 0.38rem;

						.quanIco {
							width: 0.64rem;
							height: 0.29rem;
							display: inline;
							margin-right: 0.1rem;
						}

						.bookName {
							font-size: 0.28rem;
							color: #151515;
							display: inline;
						}

					}

					.price {
						font-size: 0.26rem;
						color: #da5d50;
						position: absolute;
						bottom: 0.2rem;
						left: 0;
						.originalPrice{
							position: absolute;
							left: calc(100% + 0.2rem);
							top: 50%;
							transform: translateY(-50%);
							color: #8b8b8b;
							font-size: 0.26rem;
							margin-right: 0.2rem;
							text-decoration: line-through;
						}
					}

					.goodsNum {
						position: absolute;
						right: 0;
						bottom: 0.1rem;
					}


				}
			}
		}

		.myBookCards {
			height: 0.76rem;
			line-height: 0.76rem;
			border-bottom: 0.1rem solid #ededed;

			.hasCard {
				position: relative;
				padding-left: 0.29rem;

				.ico3 {
					width: 0.28rem;
					height: 0.2rem;
					display: inline-block;
					vertical-align: middle;
					margin-right: 0.22rem;
				}

				span {
					font-size: 0.24rem;
					color: #2c2b2b;
					display: inline-block;
					vertical-align: middle;
				}

				.noCardDes {
					position: absolute;
					right: 0.3rem;
					top: 50%;
					transform: translateY(-50%);
					font-size: 0.24rem;
					color: #2c2b2b;
				}

				.ico4 {
					position: absolute;
					right: 0.3rem;
					top: 50%;
					transform: translateY(-50%);
					width: 0.26rem;
					height: 0.26rem;
					border: 1px solid #9f9f9f;
					border-radius: 0.04rem;
					overflow: hidden;

					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.goodsDet {
			padding: 0.29rem;

			.eachDet {
				margin-bottom: 0.28rem;
				font-size: 0.24rem;
				color: #2c2b2b;

				.red {
					color: #da5d50;
				}
			}
		}

		.orderBot {
			position: fixed;
			bottom: 1.26rem;
			left: 0;
			width: 100%;
			background-color: #fff;

			.orderTotal {
				height: 0.98rem;
				line-height: 0.98rem;
				padding-left: 0.29rem;
				padding-right: 0.33rem;

				.fl {
					font-size: 0.26rem;
					color: #151515;

					.red {
						font-weight: bold;
						color: #da5d50;
					}
				}

				.fr {
					width: 1.46rem;
					height: 0.6rem;
					line-height: 0.6rem;
					text-align: center;
					font-size: 0.24rem;
					color: #ffffff;
					border-radius: 100px;
					transform: translateY(0.19rem);
					background-image: linear-gradient(84deg,
						#d8bb91 0%,
						#cb9e6e 100%),
						linear-gradient(#e6e6e6,
						#e6e6e6);
				}
			}
		}
	}
</style>
