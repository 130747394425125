<template>
	<div  class="home">

		<div class="head">
			<img class="headPic" src="../assets/headPic.png">
			<div class="phone">
				<span>手机：</span>
				<span>18667890123</span>
			</div>
		</div>

		<div class="orderMes">

			<div class="clearfix">
				<span class="fl">物流公司：{{orderMes.courierCompName}}</span>
			</div>
			<div class="clearfix">
				<span class="fl">物流单号：{{orderMes.courierNo}}</span>
				<span data-clipboard-action="copy" class="copy2 fr" :data-clipboard-text="orderMes.courierNo" @click="copy2">复制</span>
			</div>
		</div>

		<div>
			<yd-timeline>
				<yd-timeline-item v-for="(item,index) in traces" :key="index">
					<p style="line-height: 0.4rem;font-size: 0.28rem;text-align: justify;">{{item.acceptStation}}</p>
					<p style="margin-top: 0.1rem;">{{item.acceptTime}}</p>
				</yd-timeline-item>

			</yd-timeline>
		</div>

		<FootTab></FootTab>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {TimeLine, TimeLineItem} from 'vue-ydui/dist/lib.rem/timeline';
	/* 使用px：import {TimeLine, TimeLineItem} from 'vue-ydui/dist/lib.px/timeline'; */
	
	Vue.component(TimeLine.name, TimeLine);
	Vue.component(TimeLineItem.name, TimeLineItem);
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"orderMes": [],
				"traces":[]
			}
		},
		created() {

		},
		mounted() {
			var that = this;
			// 获取分类
			that.http.getLogistics({
				"goodsOrderId": that.$route.query.id
			}).then((res) => {
				that.orderMes = res.data.data;
				that.traces = res.data.data.result.traces.reverse();
			});
		},
		methods: {
			copy1() {
				let that = this;
				let clipboard = new this.clipboard(".copy1");
				clipboard.on('success', function() {
					that.$message({
						message: '复制成功',
						type: 'success'
					});
				});
				clipboard.on('error', function() {
					that.$message({
						message: '复制失败',
						type: 'error'
					});
				});
			},
			copy2() {
				let that = this;
				let clipboard = new this.clipboard(".copy2");
				clipboard.on('success', function() {
					that.$message({
						message: '复制成功',
						type: 'success'
					});
				});
				clipboard.on('error', function() {
					that.$message({
						message: '复制失败',
						type: 'error'
					});
				});
			}
		},



	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		min-height: 100vh;
		padding-bottom: 1.46rem;
		text-align: left;

		.head {
			height: 1.56rem;
			line-height: 1.56rem;
			padding: 0px 0.36rem;
			border-bottom: 0.14rem solid #f7f7f7;

			.headPic {
				display: inline-block;
				vertical-align: middle;
				width: 0.87rem;
				height: 0.87rem;
				margin-right: 0.28rem;
			}

			.phone {
				display: inline-block;
				vertical-align: middle;
				font-size: 0.28rem;
				color: #151515;

				span:last-child {
					color: #bba184;
				}
			}
		}

		.orderMes {
			padding: 0.26rem 0.38rem;
			border-bottom: 0.14rem solid #f7f7f7;

			.clearfix {
				margin-bottom: 0.2rem;
				font-size: 0.26rem;
				color: #151515;
				height: 0.38rem;
				line-height: 0.38rem;

				.fr {
					width: 0.84rem;
					height: 0.38rem;
					border-radius: 100px;
					border: 1px solid #bba184;
					font-size: 0.24rem;
					color: #bba184;
					text-align: center;
					line-height: 0.38rem;
				}
			}
		}

	}
</style>
