<template>
  <div class="home">
    <div class="stack-wrap">
      <div class="stack-line">
        <a class="stack-all" @click="urlClick((channel = ''))">全部</a>
        <ul class="stack-frame">
          <li>
            <a
              @click="urlClick((channel = 'man'))"
              :class="{ 'btn-tag': true, active: channel === 'man' }"
              >男生</a
            >
          </li>
          <li>
            <a
              @click="urlClick((channel = 'woman'))"
              :class="{ 'btn-tag': true, active: channel === 'woman' }"
              >女生</a
            >
          </li>
        </ul>
      </div>
      <!-- <div class="stack-line">
        <a class="stack-all" @click="urlClick((wordCount = 0))">全部</a>
        <ul class="stack-frame">
          <li>
            <a
              :class="{ 'btn-tag': true, active: wordCount === '6' }"
              @click="urlClick((wordCount = '6'))"
              >50万字以下</a
            >
          </li>
          <li>
            <a
              :class="{ 'btn-tag': true, active: wordCount === '3' }"
              @click="urlClick((wordCount = '3'))"
              >50-100万字</a
            >
          </li>
          <li>
            <a
              :class="{ 'btn-tag': true, active: wordCount === '7' }"
              @click="urlClick((wordCount = '7'))"
              >100万字以上</a
            >
          </li>
        </ul>
      </div> -->
      <!-- <div class="stack-line">
        <a class="stack-all" @click="urlClick((isFinish = 0))">全部</a>
        <ul class="stack-frame">
          <li>
            <a
              :class="{ 'btn-tag': true, active: isFinish === 'Y' }"
              @click="urlClick((isFinish = 'Y'))"
              >完结</a
            >
          </li>
          <li>
            <a
              :class="{ 'btn-tag': true, active: isFinish === 'N' }"
              @click="urlClick((isFinish = 'N'))"
              >连载</a
            >
          </li>
        </ul>
      </div> -->
      <div class="stack-line">
        <a class="stack-all" @click="urlClick((fid = ''))">全部</a>
        <ul class="stack-frame" id="typeName">
          <li v-for="item in items" :key="item.fid">
            <a
              @click="urlClick((fid = item.fid))"
              :class="{ 'btn-tag': true, active: fid === item.fid }"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <Love :list="bannerList" />
  </div>
</template>
  
  <script>
import Love from "@/components/love.vue";

export default {
  components: {
    // Header,
    Love,
  },
  data() {
    return {
      channel: '', //第1行
      wordCount: 0, //第2行
      isFinish: 0, //第3行
      fid: '', //第4行
      items: [
        { fid: 2, name: "都市" },
        { fid: 7, name: "现代" },
        { fid: 9, name: "古代" },
        { fid: 8, name: "青春" },
        { fid: 6, name: "仙侠" },
        { fid: 11, name: "悬疑" },
        { fid: 5, name: "科幻" },
        { fid: 1, name: "玄幻" },
        { fid: 12, name: "同人" },
      ],
      bannerList: null,
    };
  },
  mounted() {
    this.stackClick();
  },
  methods: {
    urlClick(item) {
      this.stackClick();
    },
    stackClick() {
      let data = {
        pageSize: 30,
				pageNo: 1,
        channel:this.channel,
        fid:this.fid,
        // channel: this.channel,
        // fid: this.fid,
        // wordCount: this.wordCount,
        // isFinish: this.isFinish,
        // pageNo: 1,
        // pageSize: 10,
      };
      // this.bannerList = [
      //   {
      //     allClick: "4180748",
      //     allCommend: "17569",
      //     bookId: "47",
      //     bookName: "妙手小仙医",
      //     description:
      //       "平平无奇的医学生叶凡，遭人陷害，偶得医术传承，兼具神识怪瞳，更有一只小妙手能削铁如泥、修复肌理…… 谁能热血护花、冷眼杀敌？ 谁能治愈奇难杂症、疗遍世间顽疾？ 且看我叶神医！",
      //     fullCover: "http://img.boze66.com/cover/47/s_47.jpg",
      //     isFinish: "N",
      //     isVip: "Y",
      //     lastChapterId: "228614",
      //     lastChapterName: "第四百六十六章 大结局",
      //     lastUpdateDate: "2018-03-18 22:42:42.0",
      //     penName: "胡辣汤",
      //     readChapterDate: null,
      //     readChapterId: null,
      //     readChapterName: null,
      //     type: "0",
      //     typeName: "都市职场",
      //     wordCount: "989408",
      //   },
     
      // ];
        // bookstack(data).then((res) => {
          // this.bannerList = res.stackList;
        // });
        this.http.getEbookListX(data).then((res) => {
					console.log(res.data.data,"电子书");
          this.bannerList = res.data.data
				});
        // this.http.bookstack(data).then((res) => {
				// if (res.data.status == 1) {
        //   console.log(res, "结果");
				// }
			// });
    },
  },
};
</script>
  
  <style lang="scss">
.stack-wrap {
  margin-bottom: 9px; /* 0.75rem */
  padding: 12px 0 0 12px; /* 1rem */
  font-size: 12px; /* 1rem */
  background-color: #fff;
}

.stack-wrap .stack-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding-bottom: 8px; /* 0.4rem */
}

.stack-wrap .stack-line .stack-all {
  display: block;
  width: 40.5px; /* 3.375rem */
  height: 21px; /* 1.75rem */
  line-height: 21px; /* 1.75rem */
  text-align: center;
  border: 1px solid #eebf64;
  color: #eebf64;
  border-radius: 5px;
}

.stack-wrap .stack-line .stack-frame {
  padding-top: 1px;
  -webkit-flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.stack-wrap .stack-line .stack-frame li {
  float: left;
  height: 21px; /* 1.75rem */
  line-height: 19.5px; /* 1.625rem */
  padding-bottom: 6px; /* 0.5rem */
}

.stack-wrap .stack-line .stack-frame li .btn-tag {
  display: block;
  height: 21px; /* 1.75rem */
  line-height: 21px; /* 1.75rem */
  color: #333;
  padding: 0 10.5px; /* 0.875rem */
}

.stack-wrap .stack-line .stack-frame li .btn-tag.active {
  color: #eebf64;
}
</style>