<template>
	<div  class="home">
		<div class="mineVipHead">
			<img class="mineVipHeadPic" src="../assets/mineVipHead.png">
			<div class="mineMes">
				<img class="headPic" src="../assets/headPic.png">
				<div class="mineMesR">
					<div class="phone">{{userMes.phone}} <img v-show="!userMes.paperVip" class="novip" src="../assets/noVip.png"></div>
					<div v-show="!userMes.paperVip" class="vipDes">您还暂未开通任何会员！</div>
				</div>
			</div>
			<!-- <img class="jihuoBtn" src="../assets/jihuo.png"> -->
			<div class="vipCard">
				<img v-show="isGold" class="cardBg" src="../assets/cardBg1.png">
				<img v-show="!isGold" class="cardBg" src="../assets/cardBg2.png">
				<div @click="toVip" class="toVipBtn" v-show="!isDiamonds">立即订购</div>
				<span class="cardDate" v-show="isShowDate">将于{{userMes.vipExpireTime}}到期</span>
				<span v-show="userMes.exchangeCount" class="cardNum">剩余<i>{{userMes.exchangeCount}}张</i>兑书卡</span>
			</div>
		</div>

		<div class="privilege"> 
			<div class="privilegeList">
				<img :src="vipPic" alt="">
			</div>
		</div>

		<!-- <a @click="toVip" href="javascript:;"><img v-show="!isDiamonds" class="tobuyVip" src="../assets/toBuyVip.png"></a> -->

		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"isGold": false,
				"userMes":[],
				"isDiamonds":false,
				"isShowDate":false,
				
				"isLiantong":true,
				"vipPic":""
			}
		},
		created() {
			if (window.location.href.indexOf("unicom") == -1) {
				this.isLiantong = false;
			}
		},
		mounted() {
			// 获取用户信息
			this.http.getUserMes().then((res) => {
				if (res.data.status == 1) {
					this.userMes = res.data.data;
					
					if (this.userMes.paperVip) {
						this.vipPic = this.userMes.paperVip.detailImg
						if (this.userMes.paperVip.priorityLevel == 1) {
							this.isGold = false;
						}else{
							this.isGold = true;
						}
						this.isDiamonds = true;
						this.isShowDate = true;
					} else{
						this.isDiamonds = false;
						// 获取2个Vip
						this.http.getVipList().then((res) => {
							this.vipPic = res.data.data.vipModelList[0].detailImg;
							
							if (res.data.data.vipModelList[0].priorityLevel == 1) {
								this.isGold = false;
							}else{
								this.isGold = true;
							}
						});
						
					}
				}
			});
			
			
		},
		methods: {
			toVip(){
				this.$router.push({
					path: "/vip"
				});
			},
			copy(val) {
				let oInput = document.createElement('input');
				oInput.value = val;
				document.body.appendChild(oInput);
				oInput.select(); // 选择对象;
				document.execCommand("Copy"); // 执行浏览器复制命令
				this.$message('复制成功');
				oInput.remove();
			},
			getVipUrl(url){
				if (!this.isLiantong) {
					return;
				}
				var that = this;
				// 获取vip地址
				this.http.getVipUrl({
					"redirectUri":url
				}).then((res) => {
					if (res.data.status == 1) {
						window.location.href = res.data.msg;
					} else{
						that.$message({
							showClose: true,
							message: res.data.msg,
							type: 'error'
						});
					}
				});
			}
		},



	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		min-height: 100vh;
		padding-bottom: 2.86rem;
		text-align: left;

		.mineVipHead {
			height: 4.54rem;
			background-color: #292929;
			position: relative;

			.mineMes {
				position: absolute;
				top: 1.3rem;
				left: 0.3rem;


				.headPic {
					width: 0.92rem;
					height: 0.92rem;
					display: inline-block;
					vertical-align: middle;
				}

				.mineMesR {
					display: inline-block;
					vertical-align: middle;
					color: #fff;
					margin-left: 0.4rem;


					.phone {
						font-size: 0.34rem;
						color: #ebe4e4;
						position: relative;
						display: inline-block;

						.novip {
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: calc(100% + 0.24rem);
							width: 0.64rem;
							height: 0.2rem;
						}
					}

					.vipDes {
						font-size: 0.24rem;
						color: #ebe4e4;
						margin-top: 0.2rem;
					}
				}
			}

			.jihuoBtn {
				position: absolute;
				right: 0;
				top: 1.2rem;
				width: 1.7rem;
				height: 0.49rem;
				z-index: 1;
			}

			.vipCard {
				width: 6.61rem;
				height: 2.7rem;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 40%);
				
				.toVipBtn{
					position: absolute;
					bottom: 0.36rem;
					left: 0.41rem;
					font-size: 0.24rem;
					color: #fff;
					padding: 0.16rem 0.56rem;
					background-color: #846526;
					border-radius: 100px;
				}
				
				.cardDate{
					position: absolute;
					bottom: 0.46rem;
					left: 0.41rem;
					font-size: 0.24rem;
					color: #846526;
				}
				
				.cardNum{
					position: absolute;
					right: 0.4rem;
					top: 0.7rem;
					color: #333333;
					font-size: 0.32rem;
					
					i{
						font-size: 0.4rem;
						margin: 0px 0.04rem;
						color: #846526;
					}
				}
			}

		}

		.tobuyVip {
			position: fixed;
			bottom: 1.26rem;
			left: 0;
			width: 100%;
			height: 1.29rem;

		}

		.privilege {
			padding: 1.5rem 0rem 0px 0rem;

			.privilegeHead {
				width: 1.38rem;
				height: 0.39rem;
				margin-bottom: 0.5rem;
			}

			.privilegeList {

				.eachPrivilege {
					margin-bottom: 0.5rem;

					.privilegePic {
						width: 0.96rem;
						height: 0.96rem;
						display: inline-block;
						vertical-align: top;

					}

					.privilegeMes {
						display: inline-block;
						vertical-align: top;
						width: 5.28rem;
						margin-left: 0.3rem;

						.privilegeTit {
							font-size: 0.28rem;
							color: #2e2725;
							font-weight: bold;
							margin-bottom: 0.14rem;

						}

						.privilegeDes {
							font-size: 0.24rem;
							color: #84807f;
							line-height: 0.3rem;

							a {
								margin-left: 0.1rem;
								color: #b9a282;
							}
						}
					}

				}
			}
		}

	}
</style>
