import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import './assets/css/reset.css';       // 初始化样式

import requests from './api/api.js'
Vue.prototype.http = requests;

import VueWechatTitle from 'vue-wechat-title';
Vue.use(VueWechatTitle);

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

import clipboard from 'clipboard';
//注册到vue原型上
Vue.prototype.clipboard = clipboard;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

router.beforeEach((to,from,next) => {
	// 路由发生变化修改页面title
	if (localStorage.getItem("channelMes")) {
		document.title = eval('(' + localStorage.getItem("channelMes") + ')').channelTitle || "智慧书店";
	}else{
		document.title = "智慧书店"
	}
	
	next();
})