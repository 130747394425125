<template>
	<div  class="home">
		
	</div>
</template>

<script>
	export default {
		name: "home",
		components: {
			
		},
		data() {
			return {
				"title": ""
			}
		},
		created() {

		},
		mounted() {
			var that = this;
			
			if (that.$route.query.redirectUrl.indexOf(window.location.origin) == -1) {
				window.location.href = that.$route.query.redirectUrl + "?code=" + that.$route.query.code + "&redirectUrl=" + that.$route.query.redirectUrl;
			} else{
				that.http.getHfgMes({
					"code":that.$route.query.code
				}).then((res) => {
					if (res.data.status == 1) {
						localStorage.setItem("userMes",JSON.stringify(res.data.data));
						if (sessionStorage.getItem("loginReturn")) {
							window.location.href = sessionStorage.getItem("loginReturn");
						} else{
							this.$router.push({name: "home"})
						}
						
						// window.location.href = that.$route.query.redirectUrl;
					} else{
						this.$message({
							showClose: true,
							message: res.data.msg,
							type: 'error'
						});
					}
					
				});
			}
			
			
		},
		methods: {
			
		},



	};
</script>

<style scoped lang="scss">
	.home {
		
	}
</style>
