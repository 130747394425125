<template>
	<div  class="home">
		<div v-show="loginType" class="eachLogin">
			
			
		</div>
		<div v-show="!loginType" class="eachLogin">
			<div class="title">验证码登录</div>
			
			<div class="titleMes">未注册的手机号验证通过后将自动注册</div>
			
			<div class="phoneLogin">
				<span class="left">+86</span>
				<input v-model="loginPhone" class="input3" type="text" placeholder="请输入手机号">
			</div>
			<div class="phoneLogin">
				<input v-model="vCode" style="margin-top: 0;" class="input3" type="text" placeholder="获取验证码">
				<span @click="getCode" class="right">{{codeStatusMes}}</span>
			</div>
			<div @click="codeLogin" class="loginBtn">登录</div>
			
		</div>
		
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "login",
		components: {
			FootTab
		},
		data() {
			return {
				"title":"登录",
				loginType:false,
				loginName:"验证码登录",
				"pwPhone":"",
				"password":"",
				"userMes":{
					"user_token":""
				},
				"isLiantong":false,
				
				"loginPhone":"",
				"vCode":"",
				"codeStatusMes":"获取验证码",
				"codeStatus":false,
				"countdown":60
			}
		},
		created() {
			if (window.location.href.indexOf('unicom') != -1) {
				this.isLiantong = true;
			}
		},
		mounted() {
			localStorage.setItem("userMes",JSON.stringify(this.userMes));
		},
		methods: {
			codeLogin() {
				if (this.loginPhone == "" || this.vCode == "") {
					this.$message.error('手机号码或验证码不能为空');
					return;
				}
				
				// 登录
				this.http.codeLogin({
					"phone":this.loginPhone,
					"smsCode":this.vCode
				}).then((res) => {
					if (res.data.status == 1) {
						localStorage.setItem("userMes",JSON.stringify(res.data.data));
						if (sessionStorage.getItem("loginShouldReturn")) {
							const toUrl = sessionStorage.getItem("loginShouldReturn");
							sessionStorage.removeItem("loginShouldReturn");
							window.location.href = toUrl;
						} else{
							this.$router.push({name: "home"})
						}
						
					}else{
						this.$message({
							showClose: true,
							message: res.data.msg,
							type: 'error'
						});
					}
				});
			},
			settime(){
				var that = this;
				this.codeStatus = true;
				if (this.countdown == 0) {
				    this.codeStatusMes = "获取验证码";
				    // $('.getCode').removeClass('active');
				    this.countdown = 60;
					this.codeStatus = false;
				    return;
				} else {
					this.codeStatusMes = this.countdown + "秒后重新获取";
					this.countdown--;
				}
				setTimeout(function () {
				    that.settime();
				}, 1000)
			},
			getCode(){
				if (this.codeStatus) {
					return;
				}
				if (this.loginPhone == "") {
					this.$message.error('请输入手机号码');
					return;
				}
				this.settime();
				// 获取验证码
				this.http.getLoginCode(this.loginPhone).then((res) => {
					if (res.data.status == 1) {
						this.$message({
							showClose: true,
							message: '发送成功',
							type: 'success'
						});
					}else{
						this.$message({
							showClose: true,
							message: res.data.msg,
							type: 'error'
						});
					}
				});
			}
		},



	};
</script>

<style scoped lang="scss">
	.home {
		min-height: 100vh;
		background-color: #fff;
		padding: 0.76rem 0.72rem 0;
		.title{
			font-size: 0.46rem;
			text-align: left;
			color: #2b2a2a;
			font-weight: bold;
		}
		
		.input1{
			margin-top: 0.7rem;
			display: block;
			width: 100%;
			height: 1.12rem;
			font-size: 0.37rem;
			color: #2b2a2a;
			text-indent: 0.18rem;
			border-bottom: 1PX solid #bcbcbc;
		}
		
		.input2{
			margin-top: 0.26rem;
			display: block;
			width: 100%;
			height: 1.12rem;
			font-size: 0.37rem;
			color: #2b2a2a;
			text-indent: 0.18rem;
			border-bottom: 1PX solid #bcbcbc;
		}
		
		.phoneLogin{
			position: relative;
			padding-left: 0.8rem;
			span.left{
				position: absolute;
				top: 0.02rem;
				left: 0;
				height: 1.12rem;
				line-height: 1.12rem;
				font-size: 0.3rem;
				opacity: 0.6;
				color: #2b2a2a;
			}
			span.right{
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}
			.input3{
				margin-top: 1.38rem;
				display: block;
				width: 100%;
				height: 1.12rem;
				font-size: 0.32rem;
				color: #2b2a2a;
				text-indent: 0.25rem;
				border-bottom: 1PX solid #bcbcbc;
			}
		}
		
		
		.loginBtn{
			margin-top: 0.7rem;
			width: 5.84rem;
			height: 0.96rem;
			border-radius: 100px;
			line-height: 0.96rem;
			font-size: 0.36rem;
			color: #fff;
			// background-image: linear-gradient(-90deg, 
			// 		rgba(220, 181, 124, 0.5) 0%, 
			// 		rgba(230, 199, 154, 0.5) 52%, 
			// 		rgba(239, 217, 183, 0.5) 100%), 
			// 	linear-gradient(
			// 		#ffffff, 
			// 		#ffffff);
			background-color: #f1b65c;
			letter-spacing: 2px;
		}
		
		.loginBot{
			margin-top: 0.6rem;
			font-size: 0.3rem;
			color: #2b2a2a;
		}
		
		.titleMes{
			text-align: left;
			font-size: 0.3rem;
			line-height: 0.3rem;
			color: #2b2a2a;
			opacity: 0.6;
			margin-top: 0.42rem;
		}
		
	}
</style>
