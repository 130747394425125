<template>
	<div  class="home">
		
		<div class="myOrderHead">
			<div @click="changeTab(0)" :class="{'active':tabNum == 0}" class="eachHead">
				<span>全部</span>
			</div>
			<div @click="changeTab(1)" :class="{'active':tabNum == 1}" class="eachHead">
				<span>待付款</span>
			</div>
			<div @click="changeTab(2)" :class="{'active':tabNum == 2}" class="eachHead">
				<span>待收货</span>
			</div>
		</div>
		
		<div class="myOrderContent">
			<div v-for="(item,index) in changeCarArr" :key="index" class="eachContent">
				<div class="eachOrderHead clearfix">
					<img class="eachOrderIco" src="../assets/mineOrderIco1.png">
					<span class="eachOrderTit">商品<!-- {{item.paperGoods.buyType}} --></span>
					<span @click="deleteOrder(item.id)" v-if="item.status == '失败' || item.status == '已失效' || item.status == '已收货'" class="delete fr"><img src="../assets/delete.png" alt=""></span>
					<span class="orderStatus fr">{{item.status}}</span>
				</div>
				<div>
					
					<div v-for="(eachGoods,num) in item.orderRelation" :key="num" @click="toOrderDet(item.id)" class="orderMes">
						<img class="orderPic" :src="eachGoods.paperGoods.coverImg">
						<div class="orderMesR">
							<div class="orderName">{{eachGoods.paperGoods.goodsName}}</div>
							<div v-show="item.status == '已发货' || item.status == '支付成功'" class="orderDes">包裹君已向您飞来</div>
						</div>
					</div>
					
					<div class="orderBot">
						<div class="orderPrice clearfix">
							<span class="fr"><i v-if="item.status == '已发货' || item.status == '支付成功' || item.status == '已收货'">实付</i><i v-else>应付</i>：¥ {{item.price}}</span>
							<span class="fr">共{{totalSumAll(item)}}件商品</span>
						</div>
						
					</div>
				</div>
				
				
				<div class="orderBot">
					<div class="orderBtns clearfix">
						<span @click="toOrderDet(item.id)" v-show="item.status == '支付中'" class="logistics fr">去支付</span>
						<span @click="showMask(item.id)" v-show="item.status == '已发货'" class="sureBtn fr">确认收货</span>
						<span @click="toLogistics(item.id)" v-show="item.status == '已发货'" class="logistics fr">查看物流</span>
					</div>
				</div>
				
				
			</div>
			
			
		</div>
		
		<div v-show="maskShow" class="mask">
			<div class="maskBg"></div>
			<div class="maskCon">
				<div class="maskDes">请您确认已收到图书，否则图书可能会溜走哦</div>
				<div class="maskBot clearfix">
					<div @click="hideMask" class="maskReturn fl">取消</div>
					<div @click="takeOver" class="maskOk fr">确认收货</div>
				</div>
			</div>
		</div>
		
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"tabNum": 0,
				"maskShow":false,
				"carArr":[],
				"changeCarArr":[],
				
				"chooseId":"",
				"pageNum":1,
			}
		},
		created() {

		},
		mounted() {
			var that = this;
			// 获取订单列表
			that.http.getOrderList({
				"page":that.pageNum,
				"limit":10
			}).then((res) => {
				if (res.data.status == 1) {
					that.carArr = res.data.data;
					that.changeCarArr = res.data.data;
					that.changeCarArr.map((item)=>{
						if (item.status == 0) {
							item.status = "失败"
						} else if(item.status == 1){
							item.status = "支付成功"
						} else if(item.status == 2){
							item.status = "支付中"
						} else if(item.status == 3){
							item.status = "已失效"
						} else if(item.status == 4){
							item.status = "已取消"
						} else if(item.status == 5){
							item.status = "已发货"
						} else if(item.status == 6){
							item.status = "已收货"
						}
					})
					
				}else if(res.data.status == 99){
					sessionStorage.setItem("loginReturn",window.location.href);
                    this.$router.push({name: "login"});
				}
			});
		},
		methods: {
            totalSumAll(obj){
                var totalSumAll = 0;
                var relation = obj.orderRelation;
                relation.map((item) => {totalSumAll += item.goodsCount});
                return totalSumAll
            },
			changeTab(num) {
				var that = this;
				that.tabNum = num;
				
				that.changeCarArr = [];
				for (var i = 0;i < that.carArr.length;i++) {
					if (num == 0) {
						that.changeCarArr = that.carArr;
					}else if (num == 1){
						if (that.carArr[i].status == "支付中") {
							that.changeCarArr.push(that.carArr[i]);
						}
					}else if (num == 2){
						if (that.carArr[i].status == "已发货" || that.carArr[i].status == "支付成功") {
							that.changeCarArr.push(that.carArr[i]);
						}
					}
					
				}
				
			},
			showMask(id){
				this.chooseId = id;
				this.maskShow = true;
			},
			hideMask(){
				this.maskShow = false;
			},
			takeOver(){
				var that = this;
				// 确认收货
				that.http.takeOver({
					"id":that.chooseId
				}).then((res) => {
					if (res.data.status == 1) {
						this.maskShow = false;
						this.$message('收货成功');
						setTimeout(function(){
							history.go(0);
						},1000)
					} else{
						this.$message.error(res.data.msg);
					}
				});
			},
			toLogistics(id){
				this.$router.push({
					path: "/logistics",
					query: {
						id: id
					}
				});
			},
			toOrderDet(id){
				this.$router.push({
					path: "/orderDet",
					query: {
						id: id,
						addressId:"default"
					}
				});
			},
			deleteOrder(id){
				var that = this;
				// 删除订单
				that.http.deleteOrder({
					"id":id
				}).then((res) => {
					if (res.data.status == 1) {
						this.$message('删除成功');
						setTimeout(function () {
							history.go(0);
						},1000)
					} else{
						this.$message.error(res.data.msg);
					}
				});
			}
		},



	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		min-height: 100vh;
		padding-bottom: 1.46rem;
		text-align: left;
		padding-top: 0.9rem;
		
		
		.mask{
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1000;
			
			.maskBg{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: rgba(0,0,0,0.6);
				z-index: 1;
			}
			
			.maskCon{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				width: 5.36rem;
				height: 2.8rem;
				border-radius: 0.14rem;
				background-color: #fff;
				z-index: 2;
				overflow: hidden;
				
				.maskDes{
					height: 1.84rem;
					padding: 0px 0.58rem;
					text-align: center;
					font-size: 0.28rem;
					color: #000000;
					line-height: 0.4rem;
					border-bottom: 2px solid #c9c9c9;
					padding-top: 0.58rem;
				}
				
				.maskBot{
					height: 0.93rem;
					line-height: 0.93rem;
					font-size: 0.32rem;
					
					.maskOk{
						width: 50%;
						text-align: center;
						color: #bba184;
					}
					.maskReturn{
						width: 50%;
						text-align: center;
						color: #000000;
					}
				}
			}
			
			
		}
		
		.myOrderHead{
			height: 0.9rem;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			line-height: 0.88rem;
			overflow: hidden;
			background-color: #fff;
			
			.eachHead{
				float: left;
				width: 33.333333%;
				text-align: center;
				font-size: 0.3rem;
				color: #1b1b1b;
				font-weight: bold;
				border-bottom: 1PX solid #ebe4e4;
			}
			
			.eachHead.active{
				color: #bba184;
				
				span{
					line-height: 0.8rem;
					display: inline-block;
					border-bottom: 2PX solid #bba184;
				}
				
			}
			
		}
		
		.myOrderContent{
			height: calc(100vh - 2.36rem);
			overflow: auto;
			
			.eachContent{
				border-bottom: 0.2rem solid #f7f7f7;
				
				.eachOrderHead{
					height: 0.85rem;
					line-height: 0.85rem;
					padding: 0px 0.4rem 0px 0.27rem;
					
					.eachOrderIco{
						display: inline-block;
						vertical-align: middle;
						width: 0.28rem;
						height: 0.31rem;
					}
					
					.eachOrderTit{
						font-size: 0.3rem;
						color: #1b1b1b;
						display: inline-block;
						vertical-align: middle;
						margin-left: 0.19rem;
					}
					
					.delete{
						width: 0.4rem;
						height: 0.4rem;
						transform: translateY(0.2rem);
						margin-left: 0.2rem;
						
						img{
							display: block;
							width: 100%;
						}
					}
					
					.orderStatus{
						font-size: 0.24rem;
						color: #e13030;
					}
					
				}
			
				.orderMes{
					height: 2.4rem;
					background-color: rgba(243, 243, 243, 0.7);
					padding: 0.37rem;
					
					.orderPic{
						width: 1.32rem;
						height: 1.66rem;
						border-radius: 0.04rem;
						overflow: hidden;
						display: inline-block;
						vertical-align: middle;
					}
					
					.orderMesR{
						display: inline-block;
						vertical-align: middle;
						height: 1.66rem;
						width: 4.8rem;
						padding-left: 0.4rem;
						padding-top: 0.16rem;
						
						.orderName{
							font-size: 0.34rem;
							color: #1b1b1b;
						}
						
						.orderDes{
							font-size: 0.24rem;
							color: #db982f;
							margin-top: 0.6rem;
						}
					}
					
				}
			
				.orderBot{
					padding: 0.26rem 0.44rem 0.19rem;
					.orderPrice{
						font-size: 0.24rem;
						color: #1b1b1b;
						
						span{
							margin-left: 0.26rem;
						}
					}
					.orderBtns{
						margin-top: 0.26rem;
						
						span{
							width: 1.5rem;
							height: 0.59rem;
							text-align: center;
							line-height: 0.59rem;
							border-radius: 100px;
							font-size: 0.26rem;
						}
						.sureBtn{
							border: 1px solid #e35252;
							color: #e13030;
							margin-left: 0.33rem;
						}
						.logistics{
							border: 1px solid #1b1b1b;
							color: #1b1b1b;
						}
					}
				}
			}
		}
	}
</style>
