<template>
	<div  class="home" ref="home" v-infinite-scroll="load">
		
		<div class="searchResult">
			<div class="srHead">
				
			</div>
			<div class="srBody">
				<div @click="tobookDet(item.id)" v-for="(item,index) in searchResult" :key="index" class="eachBook">
					<img class="eachBookPic" :src="item.coverImg">
					<div class="eachBookR">
						<div class="eachBookTit">{{item.goodsName}}</div>
						<div v-show="false" class="eachBookDes">{{item.goodsIntro}}</div>
						<div class="eachBookAuthor"><img src="../assets/authorIco.png"><span>{{item.owner}}</span></div>
					</div>
				</div>
			</div>
		</div>
		
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"serarchText":"",
				"searchResult":[],
				"scroll":0,
				"preId":0,
				"pageNum":1,
				"loading":true
			}
		},
		created() {

		},
		mounted() {
			// var that = this;
			// that.http.getSpecial({
			// 	"specialId":that.$route.query.specialId
			// }).then((res) => {
			// 	console.log(res.data.data);
			// 	that.searchResult = res.data.data;
			// });
		},
		methods: {
			tobookDet(id) {
				this.scroll = this.$refs.home.scrollTop;
				this.$router.push({
					path: "/bookDet",
					query: {
						id: id
					}
				});
			},
            load(){
                var that = this;
                if (that.loading == false) {
                    return;
                }
                that.http.getSpecial({
                    "specialId":that.$route.query.specialId,
                    "page":that.pageNum,
                    "limit":10
                }).then((res) => {
                    if (res.data.data.length < 10) {
                        that.loading = false;
                        that.searchResult.push(...res.data.data);
                    } else{
                        that.searchResult.push(...res.data.data);
                    }
                    that.pageNum = that.pageNum + 1;
                });
            }
		},
		beforeRouteLeave (to, from, next) {
			this.scroll = this.$refs.home.scrollTop;
			this.preId = this.$route.query.specialId;
		    next()//一定不要忘记写
		},


	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		height: 100vh;
		padding-bottom: 1.46rem;
		text-align: left;
		padding-top: 0.3rem;
		overflow: auto;
		
		
		.searchResult{
			
			.srBody{
				padding: 0px 0.5rem 0px 0.36rem;
				
				.eachBook{
					margin-bottom: 0.45rem;
					
					.eachBookPic{
						width: 1.87rem;
						height: 2.55rem;
						border-radius: 0.04rem;
						display: inline-block;
						vertical-align: middle;
					}
					
					.eachBookR{
						width: 4.34rem;
						height: 2.55rem;
						display: inline-block;
						vertical-align: middle;
						margin-left: 0.38rem;
						position: relative;
						
						.eachBookTit{
							font-size: 0.3rem;
							line-height: 0.4rem;
							color: #b5a18d;
							margin-bottom: 0.24rem;
							overflow: hidden;
							text-overflow:ellipsis;
							white-space: nowrap;
						}
						
						.eachBookDes{
							font-size: 0.24rem;
							color: #4d4d4d;
							line-height: 0.38rem;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 3;
							overflow: hidden;
						}
						
						.eachBookAuthor{
							position: absolute;
							bottom: 0;
							left: 0;
							
							img{
								display: inline-block;
								vertical-align: middle;
								width: 0.2rem;
								height: 0.2rem;
							}
							span{
								display: inline-block;
								vertical-align: middle;
								font-size: 0.2rem;
								color: #4d4d4d;
								margin-left: 0.16rem;
							}
						}
					}
				}
			}
		}
		
	}
</style>
