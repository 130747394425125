<template>
	<div  class="home">
		
		<div class="head">
			<img class="headPic" src="../assets/headPic.png">
			<!-- <div class="phone">
				<span>手机：</span>
				<span>18667890123</span>
			</div> -->
		</div>
		
		<div class="eachLine clearfix">
			<span class="fl">用户名</span>
			<span class="fr">{{userMes.phone}}</span>
		</div>
		
		<div class="eachLine clearfix">
			<span class="fl">孩子性别</span>
			<span class="fr"></span>
		</div>
		
		<div class="eachLine clearfix">
			<span class="fl">孩子年龄</span>
			<span class="fr"></span>
		</div>
		
		<div @click="toChangePw" class="eachLine clearfix changePw">
			<span class="fl">重置密码</span>
			<span class="fr"><img src="../assets/arrow1.png"></span>
		</div>
		
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"userMes":{}
			}
		},
		created() {
			this.userMes = localStorage.getItem("userMes")
		},
		mounted() {
			var that = this;
			
		},
		methods: {
			toChangePw() {
				this.$router.push({name: "passWord"});
			}
		},



	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #f7f7f7;
		min-height: 100vh;
		padding-bottom: 1.46rem;
		text-align: left;
		
		.head{
			height: 1.56rem;
			line-height: 1.56rem;
			padding: 0px 0.36rem;
			border-bottom: 1px solid #f7f7f7;
			background-color: #fff;
			
			.headPic{
				display: inline-block;
				vertical-align: middle;
				width: 0.87rem;
				height: 0.87rem;
				margin-right: 0.28rem;
			}
			
			.phone{
				display: inline-block;
				vertical-align: middle;
				font-size: 0.28rem;
				color: #151515;
				
				span:last-child{
					color: #bba184;
				}
			}
		}
		
		.eachLine{
			background-color: #fff;
			height: 1.1rem;
			line-height: 1.1rem;
			font-size: 0.28rem;
			color: #151515;
			padding: 0px 0.5rem;
			border-bottom: 1px solid #f7f7f7;
			
			.fl{
				opacity: 0.4;
			}
			
		}
		
		.changePw{
			
			border-top: 0.08rem solid #f7f7f7;
			
			.fl{
				opacity: 1;
			}
			
			img{
				width: 0.15rem;
				height: 0.26rem;
				transform: translateY(0.33rem);
			}
		}
	}
</style>
