<template>
  <div class="home" v-if="user">
    <div v-show="show" class="read-nav" id="navMenu">
      <div class="nav-con">
        <ul class="nav-con">
          <li class="back">
            <a href="javascript:void(0)" onclick="window.history.back()"></a>
          </li>
          <!-- <li class="home"><router-link to="`/home/index`"></router-link></li> -->
        </ul>
      </div>
    </div>
    <div id="bookRead" :class="selectedColor" :style="{ 'font-size': FontSize + 'px' }">
      <div id="contentDiv" @click="show = !show" class="jsChapterWrapper book-page">
        <!-- 标题 -->
        <div class="title-wrap">
          <h2 class="read_h2_2">{{title }}</h2>
        </div>
        <!-- 内容 -->
        <div id="chapterContent" v-html="user.chapterContent"></div>

        <div class="book-fy">
          <div class="pre-fr fl" @click.stop="goToChapter(1)">上一章</div>
          <div class="fy-main fl">
            <a class="a-br" href="javascript:void(0)" @click.stop="show = !show">设置</a>
            <a id="catalog" onclick="window.history.back()"> 目录 </a>
          </div>
          <div class="pre-next fr" @click.stop="goToChapter(2)">下一章</div>
        </div>
      </div>
    </div>

    <div v-show="show" class="menu-wrapA" id="footMenu">
      <div class="chapters-set">
        <div class="pre" @click.stop="goToChapter(1)">上一章</div>
        <div class="stretching"></div>
        <div class="next" @click.stop="goToChapter(2)">下一章</div>
      </div>
      <!-- style="display:none;" -->
      <div class="menu-main">
        <router-link to="`/book/info/?id=1&active=1`">
          <span class="menu-list1"></span>
          <strong onclick="window.history.back()"> 目录 </strong>
        </router-link>
        <a @click.stop="showSize = !showSize" id="setUp">
          <span class="menu-list3"></span>
          <strong>设置</strong>
        </a>
      </div>
      <div v-show="showSize" class="pot-black" id="readSetMenu">
        <ul class="ul_1">
          <li
            class="text-add fl"
            id="smallFonts"
            @click.stop="fontsizeClick(FontSize - 1)"
          >
            A-
          </li>
          <li class="text-center fl" id="fontNum"></li>
          <li class="text-add fl" id="bigFonts" @click.stop="fontsizeClick(FontSize + 1)">
            A+
          </li>
        </ul>
        <ul class="control-btn">
          <li class="active">上下滑动</li>
        </ul>
        <ul class="ul_4">
          <li
            :class="{ active: selectedColor == 'bg-brown' }"
            class="fl color1"
            @click.stop="selectColor('bg-brown')"
          ></li>
          <li
            :class="{ active: selectedColor == 'bg-white' }"
            class="fl color2"
            @click.stop="selectColor('bg-white')"
          ></li>
          <li
            :class="{ active: selectedColor == 'bg-green' }"
            class="fl color3"
            @click.stop="selectColor('bg-green')"
          ></li>
          <li
            :class="{ active: selectedColor == 'bg-yellow' }"
            class="fl color4"
            @click.stop="selectColor('bg-yellow')"
          ></li>
          <li
            :class="{ active: selectedColor == 'bg-black' }"
            class="fl color5"
            style="margin-right: 0"
            @click.stop="selectColor('bg-black')"
          ></li>
        </ul>
      </div>
      <div class="fx-btn turnLight turnBlack"></div>
    </div>
  </div>
</template>
<script>
// import { showToast } from "vant";
import { mapGetters } from "vuex"; // 如果你使用了 Vuex
const axios = require("axios");
export default {
  data() {
    return {
      title:'',
      selectedColor: "bg-yellow",
      FontSize: 16,
      active: null,
      show: false,
      showSize: false,
      checked: false,
      userSta: false,
      user: "",
      uQo: "",
      yanse: JSON.parse(localStorage.getItem("selectedColor")),
      daxiao: JSON.parse(localStorage.getItem("FontSize")),
      checkedHC: JSON.parse(localStorage.getItem("checkedHC")),
      userToken: JSON.parse(localStorage.getItem("userToken")),
    };
  },
  computed: {
    ...mapGetters(["bookId", "chapterId"]), // 如果你有 Vuex 相关的 getters
  },
  created() {
    let list = JSON.parse(localStorage.getItem('booklist'))		
    let index = JSON.parse(localStorage.getItem('bookindex'))		
    
    this.title = list[index].chapterName
    this.bookXq(list[index].chapterId);
  },
  methods: {
    bookXq(id) {
      this.http
        .getChapterdetail({
          chapterId: id,
        })
        .then((res) => {
          if(res.data.status == 1){
            let str = res.data.data.chapterContent.trim();
            str = str
              .replace("　　", "")
              .replace("　", "")
              .replace(/\s+/g, '</p><p class="read_p_2">');
            str = '<p class="read_p_2">' + str + "</p>";
            res.data.data.chapterContent = str;
            this.user = res.data.data;
          }else if(res.data.status == 99){
          this.$message({
						showClose: true,
						message: res.data.msg,
						type: 'warning'
					});
          setTimeout(() => {
            this.$router.push({
              path: "/mine",
            });
          }, 1500);
        }else if(res.data.status == 100){
          this.$message({
						showClose: true,
						message: res.data.msg,
						type: 'warning'
					});
          setTimeout(() => {
            this.$router.push({
              path: "/vip",
            });
          }, 1500);
        }
         
        });
    },

    //调整背景颜色
    selectColor(item) {
      localStorage.setItem("selectedColor", JSON.stringify(item));
      this.selectedColor = item;
    },
    //调整字体
    fontsizeClick(num) {
      if (num < 12) {
        showToast("字体已经最小了");
        return;
      }
      if (num > 28) {
        showToast("字体已经最大了");
        return;
      }
      localStorage.setItem("FontSize", JSON.stringify(num));
      this.FontSize = num;
    },
    //上一章下一章
    goToChapter(item) {
      let list = JSON.parse(localStorage.getItem('booklist'))		
      let index = JSON.parse(localStorage.getItem('bookindex'))		
      if (item === 1) {
        if (index <= 0) {
          this.$message({
						showClose: true,
						message: '已经是第一章了',
						type: 'warning'
					});
          return;
        }
        localStorage.setItem('bookindex', JSON.stringify(index-1));
      } else {
        if (list.length == index+1) {
          this.$message({
						showClose: true,
						message: '已经是最后一章了',
						type: 'warning'
					});
          return;
        }
        localStorage.setItem('bookindex', JSON.stringify(index+1));
      }
      setTimeout(() => {
        location.reload();
      }, 10);
    },
  },
};
</script>
<style lang="scss">
.van-checkbox__icon .van-icon {
  border: 1px solid #00000047;
}
.read_p_2 {
  //   font-size: 12px;
  text-indent: 2em;
  line-height: 1.8em;
  margin-bottom: 0.9em;
}
.title-wrap {
  display: flex;
  justify-content: space-between;
}
#bookRead .jsChapterWrapper p {
  font-size: 1em;
  text-indent: 2em;
  line-height: 1.8em;
  margin-bottom: 0.9em;
}
.tousu {
  color: red;
  font-size: 12px;
  width: 50px;
}

.tousu img {
  width: 15px;
  height: 15px;
  transform: translateY(3px);
}
.read-nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #333333;
  z-index: 10;
  opacity: 0.9;
}
.read-nav .nav-con {
  overflow: hidden;
}
.read-nav .nav-con a {
  height: 50px;
  line-height: 50px;
  width: 50px;
  display: block;
}
.read-nav .nav-con .back {
  float: left;
}
.read-nav .nav-con .back a {
  // background: url(../../assets/img/read/back.png) no-repeat center center;
  background-size: 50px 50px;
}
.read-nav .nav-con .down {
  float: right;
  padding-right: 20px;
}
.read-nav .nav-con .down a {
  // background: url(../../assets/img/read/down.png) no-repeat center center;
  background-size: 50px 50px;
}
.read-nav .nav-con .home {
  float: right;
  padding-right: 20px;
}
.read-nav .nav-con .home a {
  // background: url(../../assets/img/read/home.png) no-repeat center center;
  background-size: 50px 50px;
}
.menu-wrapA {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #333333;
  z-index: 10;
  opacity: 0.9;
}
.menu-wrapA .chapters-set {
  display: flex;
  border-bottom: 1px #3d3d3d solid;
  padding: 15px 18px;
}
.menu-wrapA .chapters-set .pre,
.menu-wrapA .chapters-set .next {
  font-size: 14px;
  color: #686868;
  line-height: 20px;
}
.menu-wrapA .chapters-set .stretching {
  flex: 1;
}
.menu-wrapA .menu-main {
  height: 70px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.menu-wrapA .menu-main a {
  float: left;
  width: 20%;
  padding-top: 16px;
}
.menu-wrapA .menu-main a span {
  display: block;
  width: 25px;
  height: 20px;
  margin: 0 auto;
}
.menu-wrapA .menu-main a span.menu-list1 {
  // background: url(../../assets/img/read/menu1.png) no-repeat center center;
  background-size: 20px 18px;
}
.menu-wrapA .menu-main a span.menu-list2 {
  // background: url(../../assets/img/read/menu2.png) no-repeat center center;
  background-size: 20px 18px;
}
.menu-wrapA .menu-main a span.menu-list3 {
  // background: url(../../assets/img/read/menu3.png) no-repeat center center;
  background-size: 25px 15px;
}
.menu-wrapA .menu-main a span.menu-list4 {
  // background: url(../../assets/img/read/menu4.png) no-repeat center center;
  background-size: 16px 20px;
}
.menu-wrapA .menu-main a span.menu-list5 {
  // background: url(../../assets/img/read/menu5.png) no-repeat center center;
  background-size: 16px 16px;
}
.menu-wrapA .menu-main a strong {
  padding-top: 8px;
  font-weight: normal;
  font-size: 10px;
  text-align: center;
  display: block;
  color: #919191;
}
.menu-wrapA .pot-black {
  padding-top: 15px;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 54px;
  background: #333333;
  z-index: 20;
}
.menu-wrapA .pot-black .ul_1 {
  height: 34px;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 15px;
  overflow: hidden;
  text-align: center;
  font-size: 18px;
  box-sizing: border-box;
}
.menu-wrapA .pot-black .ul_1 .text-add {
  width: 35%;
  height: 34px;
  line-height: 34px;
  border: 1px #7a7a7a solid;
  color: #789262;
  box-sizing: border-box;
}
.menu-wrapA .pot-black .ul_1 .text-center {
  width: 30%;
  height: 34px;
  line-height: 34px;
  color: #7a7a7a;
}
.menu-wrapA .pot-black .control-btn {
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
}
.menu-wrapA .pot-black .control-btn li {
  width: 35%;
  height: 34px;
  line-height: 32px;
  border: 1px #666 solid;
  color: #666;
  text-align: center;
  margin: 0 10px;
  box-sizing: border-box;
  font-size: 18px;
}
.menu-wrapA .pot-black .control-btn .active {
  border: 1px #cecece solid;
  color: #789262;
}
.menu-wrapA .pot-black .ul_4 {
  width: 100%;
  overflow: hidden;
  zoom: 1;
  padding: 0 15px 15px;
  box-sizing: border-box;
}
.menu-wrapA .pot-black .ul_4 li {
  width: 11%;
  margin-right: 11.25%;
  height: 32px;
  border: 1px #cecece solid;
  box-sizing: border-box;
}
.menu-wrapA .pot-black .ul_4 li.color1 {
  background: #d0c0a7;
}
.menu-wrapA .pot-black .ul_4 li.color2 {
  background: #eee;
}
.menu-wrapA .pot-black .ul_4 li.color3 {
  background: #d9e2d1;
}
.menu-wrapA .pot-black .ul_4 li.color4 {
  background: #f6f4ec;
}
.menu-wrapA .pot-black .ul_4 li.color5 {
  background: #595959;
}
.menu-wrapA .pot-black .ul_4 li.active {
  border: 2px #789262 solid;
}
.menu-wrapA .fx-btn {
  position: absolute;
  right: 20px;
  bottom: 130px;
  width: 40px;
  height: 40px;
  // background-image: url(../../assets/img/common/fx-icon.png);
  background-repeat: no-repeat;
  background-size: 33.33px 33.33px;
}
.menu-wrapA .turnLight {
  background-position: -34.5px -395px;
}
.menu-wrapA .turnLight.turnBlack {
  background-position: -93.75px -395px;
}
#bookRead {
  margin: 0 auto;
  overflow: hidden;
  padding: 30px 20px 20px;
  font-size: 16px;
  min-height: calc(5vh);
  text-align: justify;
  box-sizing: border-box;
  min-height: calc(100vh);
}

#bookRead .chapterContent p {
  font-size: 12px;
  text-indent: 2em;
  line-height: 1.8em;
  margin-bottom: 0.9em;
}
#bookRead .jsChapterWrapper .read_h2_2 {
  font-size: inherit;
  //   font-size: 14.4px;
  color: #e76940;
  //   line-height: 21.6px;
  margin-bottom: 12.6px;
  font-weight: normal;
}
#bookRead .jsChapterWrapper .book-fy {
  overflow: hidden;
  width: 100%;
  max-width: 500px;
  margin-bottom: 33.6px;
  text-align: center;
  color: #fff;
}
#bookRead .jsChapterWrapper .pre-fr {
  width: 25%;
  border-radius: 4px;
  background: #eebf64;
  height: 38px;
  line-height: 38px;
  font-size: 12px;
  margin-right: 3%;
  border-radius: 5px;
}
#bookRead .jsChapterWrapper .fy-main {
  width: 44%;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #eebf64;
}
#bookRead .jsChapterWrapper .fy-main a {
  float: left;
  width: 50%;
  height: 36px;
  line-height: 36px;
  color: #eebf64;
  background-color: #fff;
  box-sizing: border-box;
}
#bookRead .jsChapterWrapper .fy-main a.a-br {
  border-right: 1px solid #eebf64;
}
#bookRead .jsChapterWrapper .pre-next {
  width: 25%;
  border-radius: 4px;
  height: 38px;
  line-height: 38px;
  font-size: 12px;
  background: #eebf64;
}
#bookRead .jsChapterWrapper .subscribe-frame {
  font-size: 16px;
  border-top: 1px solid #999;
  padding-top: 18px;
  line-height: 28px;
}
#bookRead .jsChapterWrapper .subscribe-frame .subscribe-ts {
  font-size: 14px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#bookRead .jsChapterWrapper .subscribe-frame .subscribe-ts span {
  float: left;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: #ccc;
  position: relative;
}
#bookRead .jsChapterWrapper .subscribe-frame .subscribe-ts span i {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-left: -6px;
  margin-top: -6px;
  background-color: #eebf64;
}
#bookRead .jsChapterWrapper .subscribe-frame .subscribe-btn {
  width: 100%;
  height: 34px;
  line-height: 34px;
  background-color: #eebf64;
  color: #fff;
  text-align: center;
  border-radius: 17px;
  margin-top: 8px;
}
#bookRead .animal {
  transition: all 0.1s;
}
#bookRead .book-page {
  overflow: visible;
  height: 100%;
  -webkit-columns: calc(84vw) 1;
  -webkit-column-gap: 30px;
  columns: calc(84vw) 1;
  column-gap: 30px;
  width: 100%;
}
.bg-brown {
  background-color: #d0c0a7;
  color: #000;
}
.bg-white {
  background-color: #fcfcfc;
  color: #000;
}
.bg-green {
  background-color: #d9e2d1;
  color: #000;
}
.bg-yellow {
  background-color: #f6f4ec;
  color: #000;
}
.bg-black {
  background-color: #595959;
  color: #bbb;
}
.bg-night #bookRead {
  // background: url(../../assets/img/read/bg.png) repeat;
  color: #919191;
}
.fx-girl .menu-wrapA .turnLight {
  // background-image: url(../../assets/img/common/fx-icon-girl.png);
  background-repeat: no-repeat;
  background-size: 33.33px 33.33px;
}
.fx-girl #bookRead .jsChapterWrapper .book-fy {
  overflow: hidden;
  width: 100%;
  max-width: 500px;
  margin-bottom: 33.6px;
  text-align: center;
  color: #fff;
}
.fx-girl #bookRead .jsChapterWrapper .pre-fr {
  background: #f5cdd7;
}
.fx-girl #bookRead .jsChapterWrapper .fy-main {
  border: 1px solid #afaae1;
}
.fx-girl #bookRead .jsChapterWrapper .fy-main a {
  color: #afaae1;
}
.fx-girl #bookRead .jsChapterWrapper .fy-main a.a-br {
  border-right: 1px solid #afaae1;
}
.fx-girl #bookRead .jsChapterWrapper .pre-next {
  background: #f5cdd7;
}
.fx-girl #bookRead .jsChapterWrapper .subscribe-frame .subscribe-ts span i {
  background-color: #f5cdd7;
}
.fx-girl #bookRead .jsChapterWrapper .subscribe-frame .subscribe-btn {
  background-color: #f5cdd7;
}
</style>
