<template>
	<div  class="home">
		<div class="successPic"><img src="../assets/successPic.png" alt=""></div>
		<div class="sDes1">您已下单成功</div>
		<div class="sDes2">我们会尽快安排发货</div>
		<div class="btns">
			<div @click="toMineOrder" class="btn1">查看订单</div>
			<div @click="toHome" class="btn2">去书店看看</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {
			
		},
		data() {
			return {
				"title": ""
			}
		},
		created() {

		},
		mounted() {
			
		},
		methods: {
			toMineOrder(){
				this.$router.push({name: "mineOrder"})
			},
			toHome(){
				this.$router.push({name: "home"})
			}
		},



	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		min-height: 100vh;
		padding-top: 3.1rem;
		
		.successPic{
			width: 1.12rem;
			height: 1.12rem;
			margin: 0px auto;
			
			img{
				display: block;
				width: 100%;
			}
		}
		
		.sDes1{
			text-align: center;
			font-size: 0.32rem;
			color: #dbccb9;
			margin-top: 0.26rem;
		}
		
		.sDes2{
			text-align: center;
			font-size: 0.22rem;
			color: #a29e9c;
			margin-top: 0.26rem;
		}
		
		.btns{
			margin-top: 0.5rem;
			
			.btn1{
				width: 3.14rem;
				height: 0.64rem;
				line-height: 0.64rem;
				background-color: #c3aa8a;
				font-size: 0.26rem;
				color: #fff;
				margin: 0px auto;
				border-radius: 100px;
			}
			
			.btn2{
				width: 3.14rem;
				height: 0.64rem;
				line-height: 0.64rem;
				background-color: #fff;
				font-size: 0.26rem;
				color: #c3aa8a;
				border: 1PX solid #c3aa8a;
				margin: 0px auto;
				border-radius: 100px;
				margin-top: 0.3rem;
			}
		}
		
	}
</style>
