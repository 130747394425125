<template>
	<div  class="home">
		
	</div>
</template>

<script>
	// import FootTab from "@/components/FootTab.vue";
	import wx from "weixin-js-sdk";
	export default {
		name: "home",
		components: {},
		data() {
			return {
				"title": "",
				"isWX": false,
				"adressMesArr": [],
				"failUrl": "",
			}
		},
		created() {

		},
		mounted() {
			var that = this;
			let isWeiXin = () => {
				return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
			}
			that.isWX = isWeiXin();
			
			that.failUrl = that.$route.query.failUrl;
            var goodsData = {};
			
			// 获取地址
			if (that.$route.query.addressId == "default") {
				that.http.getAddress().then((res) => {
					if (res.data.status == 99) {
						this.$message({
							showClose: true,
							message: '您未登录，请登录后再次操作',
							type: 'error'
						});
						that.wxState()
						return;
					}
					that.adressMesArr = res.data.data;
				})
			} else {
				that.http.getAllAddress().then((res) => {
					if (res.data.status == 99) {
						this.$message({
							showClose: true,
							message: '您未登录，请登录后再次操作',
							type: 'error'
						});
						that.wxState()
						return;
					}
					for (var i = 0; i < res.data.data.length; i++) {
						if (res.data.data[i].id == that.$route.query.addressId) {
							that.adressMesArr = res.data.data[i];
						}
					}
				})
			}
			
            if (window.location.href.indexOf("&num") == -1) {
                that.http.getOrderDet({
                    "id":that.$route.query.id
                }).then((res) => {
                    for (var i = 0; i < res.data.data.orderRelation.length; i++) {
                        var relation = res.data.data.orderRelation[i];
                        goodsData["relationList[" + i + "].goodsId"] = relation.paperGoods.id;
                        goodsData["relationList[" + i + "].goodsCount"] = relation.goodsCount;
                    }
                })
            }

			// 把code传给后端
			that.http.getWxOpenId({
				"code": that.$route.query.code
			}).then((res) => {

				// 拼接要传递的参数
				var goodsIdArr = that.$route.query.id.split("_");
				var goodsNumArr = [];

                goodsData.buyType = "2";
                goodsData.province = that.adressMesArr.province;
                goodsData.city = that.adressMesArr.city;
                goodsData.district = that.adressMesArr.district;
                goodsData.address = that.adressMesArr.address;
                goodsData.receiverName = that.adressMesArr.receiverName;
                goodsData.receiverMobile = that.adressMesArr.receiverMobile;
                goodsData.zip = that.adressMesArr.zip;
                goodsData.domain = window.location.hostname;

				if (window.location.href.indexOf("&num") == -1) {
                    goodsData.id = that.$route.query.id;

				} else{

                    goodsNumArr = that.$route.query.num.split("_");
                    for (var i = 0; i < goodsIdArr.length; i++) {
                        goodsData["relationList[" + i + "].goodsId"] = goodsIdArr[i];
                        goodsData["relationList[" + i + "].goodsCount"] = goodsNumArr[i];
                    }
				}

				
				// 下单
				that.http.takeOrder(goodsData).then((res) => {
					// window.location.href = "http://wxpay.aizhishifm.com/?redirectUrl=" + encodeURIComponent(res.data.data);
					// 跳转公共下单地址
					window.location.href = "http://wxpay.aizhishifm.com/?data=" + encodeURIComponent(JSON.stringify(res.data.data))
						+ "&backUrl=" + encodeURIComponent(window.location.origin + '/page/success')
						+ "&failUrl=" + encodeURIComponent(that.failUrl);

				})
				
			})
			
			


		},
		methods: {
			wxState(){
				let that = this;
				let state = false; //是否嵌套在小程序里
				wx.miniProgram.getEnv(function (res) {
					if (res.miniprogram == true) {
						state = true;
					}
				});
				if (state == true) {
					console.log("我在小程序中");
					wx.miniProgram.navigateTo({ url: "/pages/login/login" });
				} else {
					console.log("我在网页中");
					sessionStorage.setItem("loginReturn", window.location.href);
					that.$router.push({ name: "login" });
				}
			},
		},
		computed: {}


	};
</script>

<style scoped lang="scss">
	.home {}
</style>
