<template>
	<div class="home" ref="home">
		<div class="head">
			<span v-if="channelMes.showTitle" class="headTit">{{channelMes.channelTitle || "智慧书店"}}</span>
			<img @click="toSearch" class="headSearch" src="../assets/search.png">
			<!-- <img class="headShare" src="../assets/shareIco.png"> -->
		</div>
		<div v-if="bannerArr.length > 0" class="banner">
			<!-- <el-carousel trigger="click" height="2.9rem">
				<el-carousel-item v-for="(item,index) in bannerArr" :key="index">
					<a :href="item.href"><img :src="item.coverImg"></a>
				</el-carousel-item>
			</el-carousel> -->
			<yd-slider autoplay="3000">
				<yd-slider-item v-for="(item,index) in bannerArr" :key="index">

					<!-- <div v-if="item.href.indexOf('https://hfgo.wo.cn/pay') != -1 && isLiantong"><a :href="item.href"><img :src="item.coverImg"></a></div>
					
					<div v-else>
						<a v-if="item.href.indexOf('https://hfgo.wo.cn/pay') != -1" href="javascript:;"><img :src="item.coverImg"></a>
						<a v-else :href="item.href.replace(item.href.split('/')[2],origin)"><img :src="item.coverImg"></a>
					</div> -->

					<div v-if="item.href.indexOf('https://hfgo.wo.cn/pay') != -1">
						<a v-if="isLiantong" :href="item.href"><img :src="item.coverImg"></a>
						<a v-else href="javascript:;"><img :src="item.coverImg"></a>
					</div>
					<div v-else>
                        <a :href="item.href == '' ? 'javascript:;' : item.href"><img :src="item.coverImg"></a>
					</div>

				</yd-slider-item>
			</yd-slider>
		</div>

		<!-- 当月福利/专区 -->
		<yd-slider v-if="channelMes.special == 1" autoplay="5000" class="specialArea">
			<yd-slider-item v-for="(item,index) in specialAreaArr" :key="index">
				<div v-for="(eachItem,index2) in item" :key="index2" class="eachSpecialArea">
					<a :href="eachItem.reqUrl">
						<img class="specialAreaPic" :src="eachItem.coverImg" alt="">
						<div class="specialAreaTit">{{eachItem.specialName}}</div>
					</a>
				</div>
				
			</yd-slider-item>
		</yd-slider>
		

		<!-- 新书推荐 -->
		<div v-for="(recommend,recommendIndex) in recommendArr" :key="recommendIndex" class="eachClassify">
			<div class="eachHead clearfix">
				<span class="eachTit fl">{{recommend.recommendName}}</span>
				<span @click="toIndexClassify(recommend.id)" class="moreBtn fr">更多</span>
			</div>

			<div class="eachBookList">
				<div @click="tobookDet(item.id)" v-for="(item,index) in recommend.goodsList" :key="index" class="eachBook">
					<img :src="item.coverImg">
					<div class="bookName">{{item.goodsName}}</div>
					<!--<div class="bookName">-->
						<!--<span class="priceInfo">1本</span>-->
						<!--<span class="priceNum">￥{{item.discountPrice > 0 ? item.discountPrice : item.price}}</span>-->
						<!--<span class="originalPrice" v-if="item.discountPrice > 0">￥{{item.price}}</span>-->
					<!--</div>-->
				</div>
			</div>
		</div>

		<!-- 广告 -->
		<div v-if="vipPics.vipModelList.length > 0" class="ad">
			<!-- <a @click="getVipUrl('https://hfgo.wo.cn/pay?gId=1227')" href="javascript:;"><img :src="vipPics[0].coverImg"></a> -->
			<a @click="toVip" href="javascript:;"><img :src="vipPics.vipModelList[0].coverImg"></a>
		</div>

		<!--&lt;!&ndash; 畅销图书 &ndash;&gt;-->
		<!--<div v-if="channelMes.sellerRecommend == '1'" class="eachClassify">-->
			<!--<div class="eachHead clearfix">-->
				<!--<span class="eachTit fl">畅销图书</span>-->
				<!--<span @click="toIndexClassify(2)" class="moreBtn fr">更多</span>-->
			<!--</div>-->

			<!--<div class="eachBookList">-->
				<!--<div @click="tobookDet(item.id)" v-for="(item,index) in sellWellArr" :key="index" class="eachBook">-->
					<!--<img :src="item.coverImg">-->
					<!--<div class="bookName">{{item.goodsName}}</div>-->
				<!--</div>-->
			<!--</div>-->
		<!--</div>-->

		<!--&lt;!&ndash; 文创产品 &ndash;&gt;-->
		<!--<div v-if="channelMes.creationRecommend == '1'" class="eachClassify">-->
			<!--<div class="eachHead clearfix">-->
				<!--<span class="eachTit fl">文创产品</span>-->
				<!--<span @click="toIndexClassify(3)" class="moreBtn fr">更多</span>-->
			<!--</div>-->

			<!--<div class="eachBookList">-->
				<!--<div @click="tobookDet(item.id)" v-for="(item,index) in creationArr" :key="index" class="eachBook">-->
					<!--<img :src="item.coverImg">-->
					<!--<div class="bookName">{{item.goodsName}}</div>-->
				<!--</div>-->
			<!--</div>-->
		<!--</div>-->

		<!-- 电子书 -->
		<!-- v-if="ebookArrShow" -->
		<div  class="eachClassify">
			<div class="eachHead clearfix">
				<span class="eachTit fl">电子书</span>
				<span @click="bookmore()" class="moreBtn fr">更多</span>
			</div>

			<div class="eachBookList">
				<div @click="toEbookDet(item.bookId)" v-for="(item,index) in ebookArr" :key="index" class="eachBook">
					<img :src="item.cover">
					<div class="bookName">{{item.bookName}}</div>
				</div>
			</div>
		</div>

		<!-- 广告 -->
		<div class="ad" v-if="vipPics.vipModelList.length == 2">
			<!-- <a @click="getVipUrl('https://hfgo.wo.cn/pay?gId=1226')" href="javascript:;"><img :src="vipPics[1].coverImg"></a> -->
			<a @click="toVip" href="javascript:;"><img :src="vipPics.vipModelList[1].coverImg"></a>
		</div>

		<!-- 猜你喜欢 -->
		<div class="eachClassify">
			<div class="eachHead clearfix">
				<span class="eachTit fl">猜你喜欢</span>
				<span style="width: 1.04rem;" @click="changeRandom" class="moreBtn fr">换一批</span>
			</div>

			<div class="eachBookList">
				<div @click="tobookDet(item.id)" v-for="(item,index) in randomBook" :key="index" class="eachBook">
					<img :src="item.coverImg">
					<div class="bookName">{{item.goodsName}}</div>
					<!--<div class="bookName">-->
						<!--<span class="priceInfo">1本</span>-->
						<!--<span class="priceNum">￥{{item.discountPrice > 0 ? item.discountPrice : item.price}}</span>-->
						<!--<span class="originalPrice" v-if="item.discountPrice > 0">￥{{item.price}}</span>-->
					<!--</div>-->
				</div>
			</div>
		</div>

		<div v-show="ejectShow" class="eject">
			<div @click="closeEject" class="mask"></div>
			<div class="ejectCon">
				<div class="ejectPic"><img src="../assets/homeToast.png" alt=""></div>
				<div class="ejectDes">
					{{channelMes.channelTitle}}是为中高端客户打造的一款会员制阅读产品。<br><br>
					提供热销纸书、精品文创，电子读物的服务。<br><br>
					会员可以免费畅读电子书，每月可在书店内挑选纸书或文创产品，包邮到家。
				</div>

				<div @click="toVip" class="toVipBtn"></div>

				<div @click="closeEject" class="closeBtn"></div>
			</div>
		</div>

		<FootTab v-if="isRouterAlive"></FootTab>
	</div>
</template>

<script>
	import Vue from 'vue';
	import FootTab from "@/components/FootTab.vue";
	import wx from "weixin-js-sdk";
	import {
		Slider,
		SliderItem
	} from 'vue-ydui/dist/lib.rem/slider';
	Vue.component(Slider.name, Slider);
	Vue.component(SliderItem.name, SliderItem);
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"bannerArr": [],
				"recommendArr": [],
				"ebookArr": [],
				"randomBook": [],
				"vipPics": {
                    'vipModelList':[]
				},
				"scroll": 0,

				"ejectShow": false,
				"isRouterAlive": false,

				"userMes": [],

				"isLiantong": true,
				"origin": '',

				"channelMes": {},

				"specialAreaArr": [],

				"ebookArrShow":false,
			}
		},
		activated() {
			this.$refs.home.scrollTop = this.scroll;
		},
		created() {
			this.origin = window.location.href.split('/')[2];
			if (window.location.href.indexOf("unicom") == -1) {
				this.isLiantong = false;
			}
			if (localStorage.getItem("ejectShow") == "false") {
			    console.log(this.ejectShow)
				this.ejectShow = false;
			}else if(window.location.href.indexOf("wozhifu") != -1){
                console.log(this.ejectShow)
                this.ejectShow = false;
			}else {
                console.log(this.ejectShow)
				localStorage.setItem("ejectShow", "true");
				this.ejectShow = true;
			}
			
			
			
		},
		mounted() {
			var that = this;
			// 获取用户信息
			this.http.getUserMes().then((res) => {
				if (res.data.status == 1) {
					this.userMes = res.data.data;
					this.status = res.data.status;
				}
			});

            // 获取渠道信息
            this.http.getChannelInfo({}).then((res) => {

                if (res.data.status == 1) {
                    localStorage.setItem("channelMes", JSON.stringify(res.data.data));
                    this.channelMes = eval('(' + localStorage.getItem("channelMes") + ')');
					localStorage.setItem('vipType',res.data.data.vipType);
					if(res.data.data.vipType == 0){
						this.ejectShow = false;
					}
                    this.isRouterAlive = true;
                } else {
                    that.$message({
                        showClose: true,
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            });

			// 获取banner
			that.http.getIndeBanner().then((res) => {
				
				that.bannerArr = res.data.data;
				
			});
			
			// 获取专区列表
			that.http.getSpecialArea().then((res) => {
				
			that.specialAreaArr = that.grouping(4,res.data.data);
				
			});

            // 获取推荐
            that.http.getRecommend().then((res) => {

                that.recommendArr = res.data.data;
            });

			// 获取电子书
			// that.http.getEbookList({
			// 	"limit": 6,
			// 	"page": 1
			// }).then((res) => {
			// 	console.log(res,"电子书");
			// 	that.ebookArr = res.data.data.books;
			// });
			
			// if (location.href.indexOf("chinaso") != -1) {
				that.http.getEbookListX({
				"pageSize": 6,
				"pageNo": 1
				}).then((res) => {
					console.log(res.data.data,"电子书");
					that.ebookArr = res.data.data;
					this.ebookArrShow = true
				});
			// }
			// 获取猜你喜欢
			that.http.getRandom({
				"limit": 6
			}).then((res) => {
				
				that.randomBook = res.data.data;
			});

			if(this.channelMes.vipType == 1){
                // 获取2张Vip图
                that.http.getVipList().then((res) => {

                    that.vipPics = res.data.data;
                });
			}

		},
		methods: {
			bookmore(id) {
			    this.$router.push({
					path: "/booklist",
					
				});
			},
            tobookList(recommendId) {
                // 获取猜你喜欢
                this.http.getRecommendBook({
                    "limit": 6,
                    "page": 1,
                    "recommendId": recommendId,
                }).then((res) => {
                    return res.data.data;
                });
            },
			tobookDet(id) {
				this.scroll = this.$refs.home.scrollTop;
				this.$router.push({
					path: "/bookDet",
					query: {
						id: id
					}
				});
			},
			toEbookDet(id) {
				// 获取电子书详情
				let that = this
				if (this.userMes.phone) {
					this.$router.push({
					path: "/bookdetail",
					query: {
						id: id
					}
				});
					// this.http.getEbookDet({
					// 	"phone": eval('(' + localStorage.getItem("userMes") + ')').phone,
					// 	"cntidx": id
					// }).then((res) => {
					// 	if (res.data.data) {
					// 		window.location.href = res.data.data.contentUrl;
					// 	} else {
					// 		this.$dialog.toast({
					// 			mes: res.data.msg,
					// 			timeout: 1500
					// 		});
					// 	}
					// });
				} else {
					localStorage.clear();
					this.$message({
						showClose: true,
						message: '您未登录，请登录后再次操作',
						type: 'error'
					});
					that.wxState()
				}

			},
			toSearch() {
				this.$router.push({
					name: "search"
				});
				this.scroll = this.$refs.home.scrollTop;
			},
			changeRandom() {
				// 获取猜你喜欢
				this.http.getRandom({
					"limit": 6
				}).then((res) => {
					this.randomBook = res.data.data;
				});
			},
			toIndexClassify(id) {
				this.$router.push({
					path: "/indexClassify",
					query: {
						id: id
					}
				});
			},
			toVip() {
				this.$router.push({
					path: "/vip"
				});
			},
			closeEject() {
				localStorage.setItem("ejectShow", "false");
				this.ejectShow = false;
			},
			getVipUrl(url) {
				if (!this.isLiantong) {
					return;
				}
				if (this.userMes.phone) {
					var that = this;
					// 获取vip地址
					this.http.getVipUrl({
						"redirectUri": url
					}).then((res) => {
						if (res.data.status == 1) {
							window.location.href = res.data.msg;
						} else {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: 'error'
							});
						}
					});

				} else {
					this.wxState()
				}

			},
			grouping(num,dataArr){
                var finalArr = [];
                var finalArrLength = Math.ceil(dataArr.length / num);
                for (let i = 0; i < finalArrLength; i++) {
                    finalArr[i] = [];
                    if (i == finalArrLength - 1 && dataArr.length % num != 0) {
                        for (let k = 0; k < dataArr.length % num; k++) {
                            finalArr[i].push(dataArr[i * num + k]);
                        }
                    } else {
                        for (let k = 0; k < num; k++) {
                            finalArr[i].push(dataArr[i * num + k]);
                        }
                    }
                }
				
				return finalArr;
			},
			wxState(){
				let that = this;
				let state = false; //是否嵌套在小程序里
				wx.miniProgram.getEnv(function (res) {
					if (res.miniprogram == true) {
						state = true;
					}
				});
				if (state == true) {
					console.log("我在小程序中");
					wx.miniProgram.navigateTo({ url: "/pages/login/login" });
				} else {
					console.log("我在网页中");
					sessionStorage.setItem("loginReturn", window.location.href);
					that.$router.push({ name: "login" });
				}
			},
		},

		beforeRouteLeave(to, from, next) {
			this.scroll = this.$refs.home.scrollTop;
			next() //一定不要忘记写
		},
		

	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		height: 100vh;
		padding-bottom: 1.46rem;
		text-align: left;
		overflow: auto;

		.head {
			line-height: 1.02rem;
			// height: 1.02rem;
			padding-left: 0.4rem;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.headTit {
				font-size: 0.42rem;
				color: #151515;
				display: inline-block;
				vertical-align: middle;
			}

			.headSearch {
				width: 3.55rem;
				height: 0.52rem;
				display: inline-block;
				vertical-align: middle;
				margin-right: 0.36rem;
			}

			.headShare {
				width: 0.45rem;
				width: 0.45rem;
				display: inline-block;
				vertical-align: middle;
			}
		}

		.banner {
			width: 6.76rem;
			height: 2.9rem;
			margin: 0px auto;
			border-radius: 0.1rem;
			overflow: hidden;
		}

		.eachSpecialArea {
			display: inline-block;
			width: 25%;

			.specialAreaPic {
				width: 40%;
				margin: 0px auto;
			}

			.specialAreaTit {
				font-size: 0.24rem;
				color: #686868;
				text-align: center;
				margin-top: 0.26rem;
			}
		}

        .specialArea {
            margin-top: 0.2rem;
            /* Chrome Safari */
        }

		.specialArea::-webkit-scrollbar {
			display: none;
			/* Chrome Safari */
		}

		.eachClassify {
			padding: 0px 0.53rem;
			margin-top: 0.32rem;

			.eachHead {

				.eachTit {
					color: #151515;
					font-size: 0.34rem;
					position: relative;
					line-height: 0.34rem;
				}

				.eachTit:before {
					content: "";
					width: 0.04rem;
					height: 0.32rem;
					background-color: #d45b4e;
					position: absolute;
					top: 0;
					left: -0.17rem;
				}

				.moreBtn {
					display: block;
					width: 0.74rem;
					height: 0.36rem;
					border: 1px solid #151515;
					color: #151515;
					line-height: 0.36rem;
					font-size: 0.2rem;
					border-radius: 100px;
					opacity: 0.2;
					text-align: center;
				}
			}

			.eachBookList {
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				// justify-content: space-between;
				margin-top: 0.36rem;

				.eachBook {
					width: 1.87rem;
					margin-bottom: 0.24rem;
					margin-right: 0.412rem;

					img {
						display: block;
						width: 100%;
						height: 2.49rem;
						margin-bottom: 0.15rem;
						border-radius: 0.12rem;
					}

					.bookName {
						font-size: 0.23rem;
						color: #151515;
						line-height: 0.32rem;

						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-bottom: .02rem;

						.priceNum{
							color: #e17a79;
							font-weight: bold;
							display: inline-block;
							float: right;
						}
						.originalPrice{
							color: #8b8b8b;
							display: inline-block;
							text-decoration: line-through;
							margin-right: .05rem;
							float: right;
						}
					}
				}

				.eachBook:nth-child(3n) {
					margin-right: 0;
				}
			}
		}

		.ad {
			width: 6.8rem;
			// height: 1.81rem;
			margin: 0.33rem auto 0.7rem;
		}

		.eject {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 100;

			.mask {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.4);
				z-index: 1;
			}

			.ejectCon {
				position: absolute;
				width: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;

				.ejectDes {
					position: absolute;
					top: 3.52rem;
					left: 1.82rem;
					width: 4.16rem;
					height: 3.2rem;
					overflow: auto;
					color: #d79a7d;
					font-size: 0.24rem;
					line-height: 0.4rem;
				}

				.toVipBtn {
					position: absolute;
					bottom: 1.95rem;
					left: 50%;
					transform: translateX(-50%);
					width: 4.09rem;
					height: 0.65rem;
				}

				.closeBtn {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 0.7rem;
					height: 0.7rem;

				}
			}


		}
	}
</style>
