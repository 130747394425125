<template>
	<div  class="home">
		
		<div v-for="(item,index) in qaArr" :key="index" class="eachQA">
			<div class="question"><img src="../assets/qaICo1.png"><span>{{item.title}}</span></div>
			<div class="ask"><img src="../assets/qaIco2.png"><span>{{item.content}}</span></div>
		</div>
		
		
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"qaArr":[]
			}
		},
		created() {

		},
		mounted() {
			var that = this;
			// 获取文创产品
			that.http.getCustomerQA().then((res) => {
				that.qaArr = res.data.data;
			});
		},
		methods: {
			playAudio() {
				this.isPlay = true;
				const music = this.$refs.player // 音频所在对象
				music.play() // 暂停音乐
			}
		},



	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #ededed;
		min-height: 100vh;
		padding-top: 0.17rem;
		padding-bottom: 1.46rem;
		text-align: left;
		
		.eachQA{
			
			width: 7.12rem;
			margin: 0px auto;
			padding: 0.49rem 0.52rem 0.68rem;
			background-color: #fff;
			border-radius: 0.04rem;
			overflow: hidden;
			margin-bottom: 0.14rem;
			
			.question{
				margin-bottom: 0.42rem;
				span{
					font-size: 0.3rem;
					color: #151515;
					margin-left: 0.24rem;
					width: 5.3rem;
					line-height: 0.4rem;
				}
				
				img{
					display: inline-block;
					vertical-align: middle;
					width: 0.43rem;
					height: 0.43rem;
				}
			}
			
			.ask{
				
				span{
					font-size: 0.3rem;
					color: #151515;
					margin-left: 0.24rem;
					width: 5.3rem;
					line-height: 0.4rem;
				}
				
				img{
					display: inline-block;
					vertical-align: middle;
					width: 0.43rem;
					height: 0.43rem;
				}
			}
			
		}
		
	}
</style>
