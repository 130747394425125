<template>
	<div class="address">

		<div class="address-b">
			<div v-if="addressIsNull" class="abCon">
				<div class="eachAdd" @click="toPlaceOrder(item.id)" v-for="(item,index) of adressMesArr" :key="index">
					<div class="adressMes">
						<div class="am-h">
							<span>{{item.receiverName}}</span><span>{{item.receiverMobile}}</span><span v-show="item.isDefault" class="default">默认</span>
						</div>
						<div class="am-b">
							<span>{{item.province + item.city + item.district + item.address}}</span>
						</div>
					</div>
					<div @click="toAddAddress(item.id)" @click.stop class="icon2">
						<img src="../assets/edit.png" />
					</div>
				</div>
			</div>
			<div v-else class="addressNull">
				<div class="addressNull-pic"><img src="../assets/addressNull.png" /></div>
				
			</div>
			<div @click="toAddAddress('add')" class="addAddress">+ 新建收货地址</div>
		</div>
		
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		components: {
			FootTab
		},
		data() {
			return {
				msg: "",
				addressIsNull: false,
				adressMesArr: [],
				fromPath: "",
				userId: "",
			}
		},
		mounted() {
			this.fromPath = sessionStorage.getItem("fromPath");
			this.userId = sessionStorage.getItem("userId");
			this.http.getAllAddress({
				userId: this.userId
			}).then((res) => {
				if (res.data.data == "") {
					this.addressIsNull = false;
				} else{
					this.addressIsNull = true;
					this.adressMesArr = res.data.data;
				}
				
			});
			var code = this.$route.query.code;
			if (typeof code !== 'undefined' && code !== '' && code != null) {
				this.http.getWeixinOpenid({
					code: code,
					userId: this.userId,
				}).then((res) => {
					if (res.data.status !== 1) {
						this.$dialog.toast({
							mes: res.data.msg,
							timeout: 1500
						});
					}
				})
			}
		},
		methods: {
			prev() {
				this.$router.go(-1);

			},
			toPlaceOrder(addressId) {
				var fromPath = sessionStorage.getItem("fromPath");
				if (fromPath == "order") {
					var orderMes = sessionStorage.getItem("orderMes");
					var orderMesArr = orderMes.split("&");
					
					// sessionStorage.setItem("addressId",addressId);
					this.$router.replace({
						path: "/order",
						query: {
							id: orderMesArr[0].split("=")[1],
							num:orderMesArr[1].split("=")[1],
							addressId:addressId
						}
					})
				}
				
				if (fromPath == "orderDet") {
					var orderMes = sessionStorage.getItem("orderMes");
					var orderMesArr = orderMes.split("&");
					
					// sessionStorage.setItem("addressId",addressId);
					this.$router.replace({
						path: "/orderDet",
						query: {
							id: orderMesArr[0].split("=")[1],
							addressId:addressId
						}
					})
				}

			},
			toAddAddress(addressId) {
				sessionStorage.setItem("addressId", addressId);
				this.$router.push({
					path: "/addAddress"
				})
			},
			toHome() {
				this.$router.push({
					name: "home",
				})
			}
		}
	};
</script>

<style scoped lang="scss">
	.address {
		background-color: #fff;
		min-height: 100vh;
		padding-bottom: 2.26rem;
		text-align: left;

		.address-b {
			padding: 0.27rem 0.32rem 0rem;

			.abCon {
				.eachAdd {
					position: relative;
					margin-bottom: 0.2rem;
					background-color: #fff;
					border-radius: 0.1rem;
					border-bottom: solid 1px #e0e0e0;

					.adressMes {
						text-align: left;
						padding: 0.26rem 1.58rem 0.26rem 0.42rem;

						.am-h {
							font-size: 0.28rem;
							margin-bottom: 0.18rem;
							color: #4b4b4b;

							span {
								margin-right: 0.3rem;
							}

							span.default {
								width: 67px;
								height: 0.31rem;
								border-radius: 0.05rem;
								border: 1px solid #fb6834;
								font-size: 0.2rem;
								color: #fb6834;
								text-align: center;
								line-height: 0.31rem;
							}
						}

						.am-b {
							font-size: 0.24rem;
							line-height: 0.44rem;
							color: #727272;
						}
					}

					.icon2 {
						position: absolute;
						top: 0.31rem;
						right: 0.42rem;

						img {
							width: 0.29rem;
							height: 0.29rem;
						}
					}
				}


			}

			.addressNull {
				padding-top: 3.1rem;

				.addressNull-pic {
					width: 2.88rem;
					height: 5.27rem;
					margin: 0px auto;

					img {
						display: block;
						height: 100%;
						width: 100%;
					}
				}

			}

			.addAddress {
				position: fixed;
				left: 0;
				bottom: 1.26rem;
				width: 100%;
				height: 0.76rem;
				text-align: center;
				background-image: linear-gradient(-90deg, 
						#d2aa6e 0%, 
						#eacd9f 100%), 
					linear-gradient(
						#fcf6f1, 
						#fcf6f1);
				font-size: 0.3rem;
				color: #fff;
				line-height: 0.88rem;
			}
		}
	}
</style>
