<template>
  <div class="home">
    <div class="bookDetTop"><img :src="bookMes.coverImg" /></div>
    <div class="bookMes">
      <div class="bookName">
        <img v-if="bookMes.goodsType == '纸书'" src="../assets/bookDetIco1.png" />
        <img v-else-if="bookMes.goodsType == '文创'" src="../assets/bookDetIco2.png" />
        <img v-else src="../assets/bookDetIco3.png" />
        <span>{{ bookMes.goodsName }}</span>
      </div>
      <div class="bookDes">{{ bookMes.company }}</div>
      <div class="bookPrice">
        <span class="priceNum"
          >￥{{ bookMes.discountPrice > 0 ? bookMes.discountPrice : bookMes.price }}</span
        >
        <span class="originalPrice" v-if="bookMes.discountPrice > 0"
          >￥{{ bookMes.price }}</span
        >
        <span class="vipPrice" v-if="vipType != 0"
          >{{ bookMes.exchangeCount }}张书卡</span
        >
      </div>
    </div>

    <div class="toVip clearfix" v-if="userMes.paperVip == null">
      <span class="fl">{{ vipPics[0].vipName + vipPics[0].price }}元包</span>
      <span @click="toVip" class="fr">立即开通</span>
    </div>

    <div class="bookPic">
      <div class="bookPicTit"><i></i><span>详情</span></div>
      <div v-html="bookMes.goodsIntro" class="bookPic"></div>
    </div>

    <div class="bookDetBtns clearfix">
      <div @click="toShoppingCart(bookMes.id)" class="eachBtn fl">加入购物车</div>
      <div @click="toOrder(bookMes.id)" class="eachBtn fr">立即订购</div>
    </div>

    <FootTab></FootTab>
  </div>
</template>

<script>
import FootTab from "@/components/FootTab.vue";
import wx from "weixin-js-sdk";
export default {
  name: "home",
  components: {
    FootTab,
  },
  data() {
    return {
      title: "",
      bookMes: "",
      vipType: "1",
      userMes: [],

      isLiantong: true,

      vipPics: [],
    };
  },
  created() {
    if (window.location.href.indexOf("unicom") == -1) {
      this.isLiantong = false;
    }
  },
  mounted() {
    var that = this;
    that.vipType = localStorage.getItem("vipType");
    // 获取详情
    that.http
      .getBookDet({
        id: that.$route.query.id,
      })
      .then((res) => {
        that.bookMes = res.data.data;
      });

    // 获取用户信息
    this.http.getUserMes().then((res) => {
      if (res.data.status == 1) {
        this.userMes = res.data.data;
      }
    });
    // 获取2个Vip
    if (that.vipType != 0) {
      this.http.getVipList().then((res) => {
        this.vipPics = res.data.data.vipModelList;
      });
    }
  },
  methods: {
    toOrder(id) {
      var that = this;
      if (this.userMes.phone) {
        this.$router.push({
          path: "/order",
          query: {
            id: id,
            num: "1",
            addressId: "default",
          },
        });
      } else {
        this.$message({
          showClose: true,
          message: "您未登录，请登录后再次操作",
          type: "error",
        });
		this.wxState()
      }
    },
    toShoppingCart(id) {
      var that = this;

      // 添加购物车
      that.http
        .addShopPingCar({
          goodsId: id,
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.$message({
              showClose: true,
              message: "添加成功",
              type: "success",
            });
          } else if (res.data.status == 99) {
            this.$message({
              showClose: true,
              message: "您未登录，请登录后再次操作",
              type: "error",
            });
			this.wxState()
          }
        });
    },
    toVip() {
      this.$router.push({
        path: "/vip",
      });
    },
    getVipUrl(url) {
    var that = this;

      if (!this.isLiantong) {
        return;
      }
      if (this.userMes.phone) {
        // 获取vip地址
        this.http
          .getVipUrl({
            redirectUri: url,
          })
          .then((res) => {
            if (res.data.status == 1) {
              window.location.href = res.data.msg;
            } else {
				that.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
      } else {
		this.wxState()
      }
    },
	wxState(){
		var that = this;
		let state = false; //是否嵌套在小程序里
		wx.miniProgram.getEnv(function (res) {
		if (res.miniprogram == true) {
			state = true;
		}
		});
		if (state == true) {
		console.log("我在小程序中");
		wx.miniProgram.navigateTo({ url: "/pages/login/login" });
		} else {
		console.log("我在网页中");
		sessionStorage.setItem("loginReturn", window.location.href);
		that.$router.push({ name: "login" });
		}
	}
  },
};
</script>

<style scoped lang="scss">
.home {
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 2.26rem;
  text-align: left;

  .bookDetTop {
    border-bottom: 2px solid #dfdfdf;

    img {
      width: 45%;
      margin: 0px auto;
      padding: 0.3rem 0px;
      border-radius: 0.16rem;
    }
  }

  .bookMes {
    padding: 0.22rem 0.41rem 0px;

    .bookName {
      line-height: 0.44rem;
      img {
        display: inline;
        width: 0.72rem;
        height: 0.44rem;
        vertical-align: middle;
        margin-right: 0.22rem;
      }
      span {
        font-size: 0.3rem;
        color: #2c2b2b;
        font-weight: bold;
        display: inline;
        vertical-align: middle;
      }
    }

    .bookDes {
      font-size: 0.26rem;
      color: #2c2b2b;
      margin: 0.18rem 0px 0.43rem;
    }

    .bookPrice {
      .priceNum {
        font-size: 0.3rem;
        color: #e17a79;
        font-weight: bold;
        margin-right: 0.26rem;
        display: inline-block;
        vertical-align: middle;
      }

      .originalPrice {
        color: #8b8b8b;
        font-size: 0.26rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.2rem;
        text-decoration: line-through;
      }

      .vipPrice {
        width: 1.11rem;
        height: 0.39rem;
        text-align: center;
        line-height: 0.39rem;
        font-size: 0.24rem;
        color: #af9b9a;
        border-radius: 0.08rem;
        border: 1px solid #af9b9a;
        display: inline-block;
        vertical-align: middle;
        font-weight: bold;
      }
    }
  }

  .toVip {
    padding: 0px 0.4rem;
    background-image: url(../assets/bookDetBg1.png);
    height: 0.88rem;
    line-height: 0.88rem;
    margin-top: 0.19rem;
    background-size: cover;

    .fl {
      font-size: 0.32rem;
      color: #232424;
    }

    .fr {
      font-size: 0.32rem;
      color: #232424;
      font-weight: bold;
    }
  }

  .bookPic {
    .bookPicTit {
      font-size: 0.34rem;
      color: #151515;
      font-weight: bold;
      height: 1.16rem;
      line-height: 1.16rem;
      padding-left: 0.52rem;

      i {
        display: inline-block;
        vertical-align: middle;
        width: 0.04rem;
        height: 0.33rem;
        background-color: #d45b4e;
        position: relative;
        top: -0.02rem;
      }

      span {
        font-size: 0.34rem;
        color: #151515;
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.13rem;
      }
    }
  }

  .bookDetBtns {
    position: fixed;
    bottom: 1.26rem;
    left: 0;
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;

    .eachBtn {
      width: 50%;
      height: 0.8rem;
      text-align: center;
      color: #fff;
      font-size: 0.28rem;
      font-weight: bold;
    }

    .eachBtn.fl {
      background-color: #dbbc53;
    }

    .eachBtn.fr {
      background-color: #e17977;
    }
  }
}
</style>
