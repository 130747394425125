<template>
	<div  class="home">
		
		<!-- 分类 -->
		<div class="eachClassify">
			<div class="classifyHead">分类</div>
			<div class="eachBookList">
				<div @click="toEachClassify(item.categoryId)" v-for="item in listArr" :key="item" class="eachBook">
					<img :src="item.coverImg">
					<div class="bookName">{{item.categoryName}}</div>
				</div>
			</div>
		</div>
		
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"listArr":[]
			}
		},
		created() {

		},
		mounted() {
			var that = this;
			// 获取分类
			that.http.getList().then((res) => {
				that.listArr = res.data.data;
			});
		},
		methods: {
			toEachClassify(id) {
				this.$router.push({
					path: "/eachClassify",
					query: {
						id: id
					}
				});
			}
		},



	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		min-height: 100vh;
		padding-bottom: 1.46rem;
		text-align: left;
		
		.eachClassify{
			padding: 0px 0.44rem;
			
			.classifyHead{
				text-align: center;
				font-size: 0.38rem;
				color: #151515;
				font-weight: bold;
				padding-top: 0.29rem;
			}
			.eachBookList{
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				// justify-content: space-between;
				margin-top: 0.36rem;
				
				.eachBook{
					width: 1.87rem;
					margin-bottom: 0.34rem;
					margin-right: 0.412rem;
					
					img{
						display: block;
						width: 100%;
						height: 2.45rem;
						margin-bottom: 0.15rem;
					}
					.bookName{
						text-align: center;
						font-size: 0.28rem;
						color: #151515;
						line-height: 0.32rem;
					}
				}
				
				.eachBook:nth-child(3n){
					margin-right: 0;
				}
			}
		}
		
	}
</style>
