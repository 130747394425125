<template>
	<div class="addAddress">
		<div class="header">
			<yd-navbar fixed title="添加收货地址">
				<div class="returnBtn" @click='prev' slot="left">
					<yd-navbar-back-icon></yd-navbar-back-icon>
				</div>
				<!-- <div class="homeBtn" @click='toHome' slot="right">
					<yd-icon name="home" size=".4rem" color="#666666"></yd-icon>
				</div> -->
			</yd-navbar>
			<div v-show="hasMes" @click="removeAddress" class="removeBtn">删除</div>
		</div>
		<yd-cell-group>
			<yd-cell-item>
				<span slot="left">收货人&nbsp;&nbsp;&nbsp;&nbsp;</span>
				<yd-input slot="right" v-model="input1" placeholder="姓名"></yd-input>
			</yd-cell-item>
			<yd-cell-item>
				<span slot="left">手机号码&nbsp;&nbsp;</span>
				<yd-input slot="right" v-model="input2" required regex="mobile" placeholder="请输入手机号码"></yd-input>
			</yd-cell-item>
			<yd-cell-item arrow>
				<span slot="left">所在地区&nbsp;&nbsp;</span>
				<input slot="right" type="text" @click.stop="show1 = true" v-model="model1" readonly placeholder="选择收货地址">
			</yd-cell-item>
			<yd-cell-item>
				<span slot="left">详细地址&nbsp;&nbsp;</span>
				<yd-input slot="right" v-model="input3" placeholder="如道路、门牌号、小区、楼栋号、房号等"></yd-input>
			</yd-cell-item>
			<yd-cell-item>
				<span slot="left">邮政编码&nbsp;&nbsp;</span>
				<yd-input slot="right" v-model="input4" placeholder="邮政编码"></yd-input>
			</yd-cell-item>
			<yd-cell-item type="label">
				<div slot="left">设为默认地址</div>
				<yd-switch slot="right" v-model="ydswitch"></yd-switch>
			</yd-cell-item>
		</yd-cell-group>
		<div @click="toPlaceOrder" class="addAddress-btn">保存</div>

		<yd-cityselect class="jjjjj" v-if="hasMes" v-model="show1" ref="cityselectDemo" :callback="result1" :items="district"
		 :provance="provinceNum" :city="cityNum" :area="districtNum"></yd-cityselect>
		<yd-cityselect class="kkkkk" v-if="hasMes2" v-model="show1" :callback="result1" :items="district"></yd-cityselect>

		<!--<div>{{areaJson}}</div>-->
	</div>
</template>
<script>
	import Vue from 'vue';
	
	import YDUI from 'vue-ydui'; /* 相当于import YDUI from 'vue-ydui/ydui.rem.js' */
	import 'vue-ydui/dist/ydui.rem.css';
	/* 使用px：import 'vue-ydui/dist/ydui.px.css'; */
	
	Vue.use(YDUI);

	import Area from "../assets/areaJson.json";
	import {
		CitySelect
	} from 'vue-ydui/dist/lib.rem/cityselect';

	Vue.component(CitySelect.name, CitySelect);

	export default {
		data() {
			return {
				show1: false,
				model1: '',
				district: '',
				input1: '',
				input2: '',
				input3: '',
				input4: '',
				ydswitch: true,
				userId: "",
				provinceNum: "",
				cityNum: "",
				districtNum: "",
				receiverName: "",
				receiverMobile: "",
				isDefault: 1,

				adressMesArr: [],
				hasMes: false,//修改地址
				hasMes2: false,//新增地址

				addressId: "",
				clickIsReturn: true,

				fromPath: ""
			}
		},
		beforeMount() {
			this.district = Area;

			this.addressId = sessionStorage.getItem("addressId");
			// this.userId = sessionStorage.getItem("userId");
			if (this.addressId == "add") {
				this.ydswitch = true;
				this.hasMes2 = true;
			} else {
				this.http.getAllAddress().then((res) => {
					this.adressMesArr = res.data.data;
				}).then(() => {
					for (var i = 0; i < this.adressMesArr.length; i++) {
						if (this.adressMesArr[i].id == this.addressId) {
							this.input1 = this.adressMesArr[i].receiverName;
							this.input2 = this.adressMesArr[i].receiverMobile;
							this.input3 = this.adressMesArr[i].address;
							this.input4 = this.adressMesArr[i].zip;
							this.provinceNum = this.adressMesArr[i].province.toString();
							this.cityNum = this.adressMesArr[i].city.toString();
							this.districtNum = this.adressMesArr[i].district.toString();
							if (this.adressMesArr[i].isDefault == 1) {
								this.ydswitch = true;
							} else {
								this.ydswitch = false;
							}
							this.hasMes = true;
						}
					}
				})

			}
		},
		mounted() {
			this.fromPath = sessionStorage.getItem("fromPath");
			var that = this;
			setTimeout(function() {
				var addressTextArr = that.$refs.cityselectDemo.$el.children[1].children[0].children[1].children;
				for (var i = 0; i < addressTextArr.length; i++) {
					that.model1 += addressTextArr[i].innerText + " "
				}
				var model1 = that.model1.replace(/\s*/g, "");
				that.input3 = that.input3.replace(model1, "")
			}, 100)

		},
		methods: {
			result1(ret) {
				this.model1 = ret.itemName1 + ' ' + ret.itemName2 + ' ' + ret.itemName3;
				this.provinceNum = ret.itemName1;
				this.cityNum = ret.itemName2;
				this.districtNum = ret.itemName3;
			},
			prev() {
				this.$router.replace({
					name: "address"
				})
			},
			toPlaceOrder() {
				
				this.clickIsReturn = false;
				if (this.ydswitch) {
					this.isDefault = 1;
				} else {
					this.isDefault = 0;
				}

				if (this.hasMes) {
					var address = this.input3;
					address = address.replace(/\s*/g, "");
					this.http.modifyAddress({
						zip: this.input4,
						province: this.provinceNum,
						city: this.cityNum,
						district: this.districtNum,
						address: address,
						receiverName: this.input1,
						receiverMobile: this.input2,
						isDefault: this.isDefault,
						id: this.addressId,
					}).then((res) => {
						history.go(-1);

					})
				} else {
					var address = this.input3;
					address = address.replace(/\s*/g, "");
					if (this.input1 && this.input2 && this.input3) {
						this.http.addAddress({
							zip: this.input4,
							province: this.provinceNum,
							city: this.cityNum,
							district: this.districtNum,
							address: address,
							receiverName: this.input1,
							receiverMobile: this.input2,
							isDefault: this.isDefault
						}).then((res) => {
							history.go(-1)
						})
					}
					
				}

			},
			removeAddress() {
				this.http.removeAddress({
					"id":this.addressId
				}).then((res) => {
					history.go(-1)
				})
			}

		},
		// beforeRouteLeave(to, from, next) {
		// 	if (this.clickIsReturn) {
		// 		const answer = window.confirm('确定放弃本次修改么？')
		// 		if (answer) {
		// 			next();
		// 		} else {
		// 			next(false)
		// 		}
		// 	} else {
		// 		next();
		// 	}

		// }
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.removeBtn{
		position: fixed;
		top: 0;
		right: 0px;
		font-size: 0.32rem;
		color: #333333;
		z-index: 100;
		line-height: 1rem;
		padding: 0px 0.2rem;
	}
	.addAddress {
		padding-top: 1rem;

		.header {
			header {
				background-color: #f5f5f5 !important;
			}
		}

		.yd-cell-box {
			.yd-cell {
				.yd-cell-item {
					border-bottom: 1px solid #F5F5F5;
					background-color: #fff !important;

					.yd-cell-left {
						color: red !important;
					}
				}
			}
		}

		.addAddress-btn {
			position: fixed;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0.76rem;
			text-align: center;
			background-image: linear-gradient(-90deg, 
					#d2aa6e 0%, 
					#eacd9f 100%), 
				linear-gradient(
					#fcf6f1, 
					#fcf6f1);
			font-size: 0.3rem;
			color: #fff;
			line-height: 0.88rem;
		}
	}
</style>
