<template>
	<div  class="home">
		
		<div v-show="bookCard.length" v-for="(item,index) in bookCard" :key="index" class="eachList">
			<div @click="toHome(bookCard.status)" class="couponR" :class="{'class1' : bookCard.status == -1,'class2' : bookCard.status == 1,'class3' : bookCard.status == 0}">
				<div class="couponMes">
					<div class="couponTit">兑书卡</div>
					<div class="couponDes">
						<div v-if="bookCard.status == 1">请尽快使用</div>
						<div v-else><br></div>
						<br />
						<br />
						<br />
						有效期：{{bookCard.createTime}}-{{bookCard.expireTime}}
					</div>
					
				</div>
			</div>
		</div>
		<div class="noCard" v-show="!bookCard.length">
			暂无兑书卡
		</div>
		
		
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"bookCard":[],
				"testNum":1
			}
		},
		created() {

		},
		mounted() {
			var that = this;
			// 获取兑书卡信息
			that.http.getBookCardMes().then((res) => {
				that.bookCard = res.data.data;
			});
		},
		methods: {
			tobookDet(status) {
				if (status == 1) {
					this.$router.push({name: "home"});
				}
				
			},
		},



	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #ededed;
		min-height: 100vh;
		padding-top: 0.17rem;
		padding-bottom: 1.46rem;
		text-align: left;
		padding: 0.17rem 0.18rem 1.46rem;
		
		.noCard{
			text-align: center;
			font-size: 0.36rem;
			color: #666666;
			padding-top: 3rem;
			letter-spacing: 2px;
		}
		
		.couponR{
			margin-bottom: 0.3rem;
			padding: 0.18rem;
			background-color: #fff;
			border-radius: 0.12rem;
			background-repeat: no-repeat;
			background-position: 100% 0;
			background-size: 1.06rem 0.94rem;
		}
		
		.couponR.class3{
			background-image: url(../assets/expire.png);
		}
		.couponR.class2{
			background-image: url(../assets/noUse.png);
		}
		.couponR.class1{
			background-image: url(../assets/hasUse.png);
		}
		
	}
</style>
