<template>
  <div class="home">
    <div class="vipTitle">会员</div>
    <img class="vipBanner" :src="vipPics.vipHeadImg" />
    <div class="tab-container">
      <button
        v-for="(item, index) in vipPics.vipModelList"
        :key="index"
        :class="{ active: isActiveTab(index) }"
        @click="setActiveTab(index)"
      >
        {{ item.vipName }}
      </button>
    </div>

    <div class="eachVip" v-if="activeTab == 0">
      <a
        @click="getVipUrl(vipPics.vipModelList[0].buyUrl, vipPics.vipModelList[0])"
        href="javascript:;"
      >
        <img :src="vipPics.vipModelList[0].buyImg" />
        <span class="toDetail"></span>
        <span v-show="isShowHasbuy" class="hasBuy"
          >已有{{ vipPics.vipModelList[0].buyCount }}人订购</span
        >
      </a>
    </div>

    <div class="eachVip" v-if="activeTab != 0">
      <a
        @click="getVipUrl(vipPics.vipModelList[1].buyUrl, vipPics.vipModelList[1])"
        href="javascript:;"
      >
        <img :src="vipPics.vipModelList[1].buyImg" />
        <span class="toDetail"></span>
        <span v-show="isShowHasbuy" class="hasBuy"
          >已有{{ vipPics.vipModelList[1].buyCount }}人订购</span
        >
      </a>
    </div>

    <FootTab></FootTab>
  </div>
</template>

<script>
import FootTab from "@/components/FootTab.vue";
import wx from "weixin-js-sdk";
import axios from "axios";
export default {
  name: "home",
  components: {
    FootTab,
  },
  data() {
    return {
      title: "",
      userMes: [],
      vipPics: [],

      isLiantong: true,

      isShowHasbuy: true,

      tabs: [
        { title: "黄金会员", content: "这是第一个内容" },
        { title: "白金会员", content: "这是第二个内容" },
      ],
      activeTab: 0,
    };
  },
  created() {},
  mounted() {
    // 获取用户信息
    this.http.getUserMes().then((res) => {
      if (res.data.status == 1) {
        this.userMes = res.data.data;
      }
    });
    // 获取2个Vip
    this.http.getVipList().then((res) => {
      this.vipPics = res.data.data;
    });

    if (eval("(" + localStorage.getItem("channelMes") + ")").id == 5) {
      this.isShowHasbuy = false;
    }
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = index;
    },
    isActiveTab(index) {
      return this.activeTab == index;
    },
    playAudio() {
      this.isPlay = true;
      const music = this.$refs.player; // 音频所在对象
      music.play(); // 暂停音乐
    },
    getVipUrl(url, item) {
      let buyImg = url;
      const currentProtocol = window.location.protocol;
      const updatedBuyImg = buyImg.replace(/^https?:/, currentProtocol);
      let that = this;
      var state = false; //是否嵌套在小程序里
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram == true) {
          state = true;
        }
      });
      if (!this.isLiantong) {
        return;
      }
      if (this.userMes.phone) {
        // 获取vip地址
        this.http
          .getVipUrl({
            redirectUri: updatedBuyImg,
          })
          .then((res) => {
            if (res.data.status == 1) {
              let dataA = {
                userId: this.userMes.id,
                wxPayModel: 2, //1是h5 2是微信
              };
              if (state == true) {
                dataA.wxPayModel = 2;
              } else {
                dataA.wxPayModel = 1;
              }
              axios
                .get(res.data.msg, { params: dataA })
                .then((response) => {
                  if (response.data.status == 1) {
                    if (state == true) {
                      let sta =
                        "?timeStamp=" +
                        response.data.data.timeStamp +
                        "&nonceStr=" +
                        response.data.data.nonceStr +
                        "&package=" +
                        encodeURIComponent(response.data.data.package) +
                        "&signType=" +
                        response.data.data.signType +
                        "&paySign=" +
                        encodeURIComponent(response.data.data.paySign);
                      // 跳转到微信小程序环境内
                      wx.miniProgram.navigateTo({ url: "/pages/pay/pay" + sta });
                      // window.location.href = 'https://wxpay.chinaso.com/wxpay/index.html?redirectUrl=' +encodeURIComponent(response.data.data.orderUrl + "&backUrl=" + encodeURIComponent(window.location.href))
                    } else {
                      // 跳转到h5环境内
                      window.location.href = response.data.data.orderUrl;
                    }
                  } else {
                    that.$message({
                      showClose: true,
                      message: response.data.msg,
                      type: "error",
                    });
                  }
                })
                .catch((error) => {
                  // 处理错误
                  //   console.error("失败：", error);
                });
              // this.http.zhifuVipUrl(res.data.msg,dataA).then((res) => {
              // 		console.log(res,"zhifu55555");
              // })
              // 	vipCode:item.vipCode,
              // requrl:updatedBuyImg,
              // userId:this.userMes.id,
              // wxPayModel:2,
              // window.location.href = res.data.msg + "&userId=" + this.userMes.id;
            } else {
              that.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          });
      } else {
        if (state == true) {
          //   console.log("我在小程序中");
          wx.miniProgram.navigateTo({ url: "/pages/login/login" });
        } else {
          //   console.log("我在网页中");
          sessionStorage.setItem("loginReturn", window.location.href);
          that.$router.push({ name: "login" });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tab-container {
  display: flex;
  justify-content: center;
}

button {
  background-color: #e2e1e1;
  color: black;
  padding: 10px 20px;
  border: none;
  margin: 0 20px;
  border-radius: 5px;
}

button.active {
  background-color: #cc9e74;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.3s ease;
}

.tab-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.tab-content.show {
  opacity: 1;
  max-height: unset;
}
.home {
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 1.46rem;
  text-align: left;

  .vipTitle {
    font-size: 0.38rem;
    text-align: center;
    font-weight: bold;
    height: 0.9rem;
    line-height: 0.9rem;
  }

  .eachVip {
    margin-top: 0.1rem;
    position: relative;

    .hasBuy {
      font-size: 0.2rem;
      color: #444240;
      position: absolute;
      top: 5rem;
      left: 5.31rem;
    }
    .toDetail {
      width: 1.55rem;
      height: 0.56rem;
      position: absolute;
      top: 1.47rem;
      right: 0.67rem;
    }
  }
}
</style>
