<template>
  <div>
    <div class="demand-wrap demand-wrap-unfold">
      <div class="demand-tit" v-if="msg">
        <div class="demand-word">
          <div class="demand-word-icon">
            <div class="title">{{ msg }}</div>
          </div>
        </div>
      </div>
      <div class="demand-main">
        <div v-for="(cxmf, index) in list" :key="index" class="demand-data">
          <a @click="urlInfo(cxmf)" class="demand-book">
            <div class="book-item">
              <div class="book-item-wrap">
                <img v-if="cxmf.imgUrl" :src="cxmf.imgUrl" />
                <img v-if="cxmf.cover" :src="cxmf.cover" />
                <img v-if="cxmf.fullCover" :src="cxmf.fullCover" />
                <span
                  v-if="cxmf.isVip === 'N'"
                  class="fx-icon book-free"
                ></span>
              </div>
              <div class="book-introduction">
                <div class="book-name" v-if="cxmf.bookName">{{ cxmf.bookName }}</div>
                <div class="book-name" v-if="cxmf.bname">{{ cxmf.bname }}</div>
                <div class="book-scal-wrap">
                  <div class="book-scal">
                    <div class="book-txt">{{ cxmf.description }}</div>
                    <div class="book-tag">
                      <p id="author" v-if="cxmf.penName">{{ cxmf.penName }}</p>
                      <p id="author" v-if="cxmf.pen_name">{{ cxmf.pen_name }}</p>
                      <p id="bookstate" v-if="cxmf.isfinish">
                        {{ cxmf.isfinish === 'Y' ? '完结' : '连载' }}
                      </p>
                      <p id="bookstate" v-if="cxmf.isFinish">
                        {{ cxmf.isFinish === 'Y' ? '完结' : '连载' }}
                      </p>
                      <p id="bookstate" v-if="cxmf.is_finish">
                        {{ cxmf.is_finish === 'Y' ? '完结' : '连载' }}
                      </p>
                      <p id="bookcname" v-if="cxmf.bcname">{{ cxmf.bcname }}</p>
                      <p id="bookcname" v-if="cxmf.cname">{{ cxmf.cname }}</p>
                      <p id="bookcname" v-if="cxmf.typeName">{{ cxmf.typeName }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  props: {
    msg: String,
    list: Array
  },
  methods: {
    urlInfo(item) {
        this.$router.push({
          path: "/bookdetail",
          query: {
            id: item.bookId
          }
        })
    }
  }
};
</script>
<style scoped lang="scss">
.title {
  font-size: 15px;
  font-weight: bold;
  text-align: left;
}
#author {
    float: left;
    padding: 0 6px; /* 0.5rem * 12px = 6px */
    border: none;
    font-size: 18px; /* 1.5rem * 12px = 18px */
    color: #969696;
}
.demand-wrap {
  background-color: #fff;
}
.demand-wrap .demand-tit {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 36px; /* 3rem */
  align-items: center;
}
.demand-wrap .demand-tit .demand-word {
  -webkit-flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 4%;
}
.demand-wrap .demand-tit .demand-word .demand-word-icon {
  width: 90px; /* 7.5rem */
}
.demand-wrap .demand-tit .demand-word .demand-word-icon img {
  width: 100%;
}
.demand-wrap .demand-tit .toggle-btn {
  padding: 6px 16px; /* .5rem 1.375rem */
}
.demand-wrap .demand-tit .toggle-btn p {
  width: 9px; /* 1.25rem */
  height: 6px; /* 1rem */
  background-position: -108px -228px; /* -9rem -19rem */
}
.demand-wrap .demand-main {
  width: 98%; /* 98% */
  margin: 0 auto;
}
.demand-wrap .demand-main .demand-data {
  width: 25%; /* 25% */
  float: left;
}
.demand-wrap .demand-main .demand-data .demand-book {
  width: 66px; /* 5.5rem */
  margin: 0 auto;
  display: block;
}
.demand-wrap .demand-main .demand-data .demand-book .book-item .book-item-wrap {
  position: relative;
  width: 66px; /* 5.5rem */
  height: 88.2px; /* 7.4rem */
  border-radius: 0.75px; /* 0.1rem */
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
}
.demand-wrap
  .demand-main
  .demand-data
  .demand-book
  .book-item
  .book-item-wrap
  img {
  width: 100%;
  min-height: 100%;
  display: block;
}
.demand-wrap
  .demand-main
  .demand-data
  .demand-book
  .book-item
  .book-item-wrap
  .book-free {
  position: absolute;
  bottom: 1px; /* 0.125rem */
  right: 1px; /* 0.125rem */
  width: 15px; /* 1.25rem */
  height: 15px; /* 1.25rem */
  background-position: -408.5px -132.5px; /* -34.04167rem -11.08333rem */
}
.demand-wrap .demand-main .demand-data .demand-book .book-introduction {
  padding: 3px 0 4px; /* 0.4rem 0 0.5rem */
}
.demand-wrap
  .demand-main
  .demand-data
  .demand-book
  .book-introduction
  .book-name {
  font-size: 12px; /* 1rem */
  color: #323232;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: left;
}
.demand-wrap
  .demand-main
  .demand-data
  .demand-book
  .book-introduction
  .book-scal-wrap {
  position: relative;
}
.demand-wrap
  .demand-main
  .demand-data
  .demand-book
  .book-introduction
  .book-scal {
  display: none;
  width: 200%; /* 200% */
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  position: absolute;
  left: 0;
  top: 0;
}
.demand-wrap-unfold .demand-tit .toggle-btn p {
  background-position: -152.4px -228px; /* -12.7rem -19rem */
}
.demand-wrap-unfold .demand-main .demand-data {
  width: 92%; /* 92% */
  float: none;
  padding: 2% 14.4px; /* 4% 1.2rem */
}
.demand-wrap-unfold .demand-main .demand-data .demand-book {
  width: 100%;
}
.demand-wrap-unfold .demand-main .demand-data .demand-book .book-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.demand-wrap-unfold
  .demand-main
  .demand-data
  .demand-book
  .book-item
  .book-introduction {
  -webkit-flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 0 0 9px; /* 0 0 0 0.75rem */
}
.demand-wrap-unfold
  .demand-main
  .demand-data
  .demand-book
  .book-introduction
  .book-name {
  text-align: left;
  -webkit-line-clamp: 1;
  height: 16.8px; /* 1.4rem */
}
.demand-wrap-unfold
  .demand-main
  .demand-data
  .demand-book
  .book-introduction
  .book-scal {
  display: block;
  padding-top: 1px; /* .1rem */
}
.demand-wrap-unfold
  .demand-main
  .demand-data
  .demand-book
  .book-introduction
  .book-scal
  .book-txt {
  color: #969696;
  font-size: 21px; /* 1.75rem */
  line-height: 33.6px; /* 2.8rem */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 12px; /* 1rem */
  text-align: left;
}
.demand-wrap-unfold
  .demand-main
  .demand-data
  .demand-book
  .book-introduction
  .book-scal
  .renew-time {
  font-size: 21px; /* 1.75rem */
  padding-top: 4.8px; /* .4rem */
  color: #ff6432;
}
.demand-wrap-unfold
  .demand-main
  .demand-data
  .demand-book
  .book-introduction
  .book-scal
  .book-tag {
  padding-top: 19.2px; /* 1.6rem */
  overflow: hidden;
}
.demand-wrap-unfold
  .demand-main
  .demand-data
  .demand-book
  .book-introduction
  .book-scal
  .book-tag
  p {
  float: right;
  padding: 4px 6px; /* 0 0.5rem */
  border-radius: 6px; /* 0.5rem */
  font-size: 18px; /* 1.5rem */
  margin-right: 12px; /* 1rem */
  border: 2px solid #eebf64; /* 2px */
  color: #eebf64;
  letter-spacing: 0.5px; /* 0.5px */
}
.fx-girl
  .demand-wrap-unfold
  .demand-main
  .demand-data
  .demand-book
  .book-introduction
  .book-scal
  .renew-time {
  color: #afaae1;
}

.fx-girl
  .demand-wrap-unfold
  .demand-main
  .demand-data
  .demand-book
  .book-introduction
  .book-scal
  .book-tag
  p {
  border: 1px solid #afaae1;
  color: #afaae1;
}
</style>
  