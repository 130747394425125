<template>
	<div id="foot-tab">
		<router-view></router-view>
		<div class="tab">
			<router-link class="home-tab" to="/home" tag="span"></router-link>
			<router-link class="classify-tab" to="/classify" tag="span"></router-link>
			<router-link :class="vipType != 0 ? 'vip-tab' : 'buy-car'" :to="vipType != 0 ? '/vip' : '/car'" tag="span"></router-link>
			<router-link class="mine-tab" to="/mine" tag="span"></router-link>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
                'vipType' : localStorage.getItem('vipType') || '1'
			}
		}
	}
</script>


<style lang="scss">
	.tab {
		display: flex;
		justify-content: space-around;
		padding: 0.21rem 0px 0.68rem;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 99;
		height: 1.26rem;
		width: 100vw;
		background-color: #fff;
		border-top: 1px solid #eee;
		box-shadow: 0px 0px 0.18rem #eaeaea;

		span {
			display: inline-block;
			background-size: contain;
			background-repeat: no-repeat;
		}

		.home-tab {
			width: 0.6rem;
			height: 0.77rem;
			background-image: url("../assets/footIco1.png");
		}

		.home-tab.router-link-active {
			background-image: url("../assets/footIco2.png");
		}

		.classify-tab {
			width: 0.49rem;
			height: 0.80rem;
			background-image: url("../assets/footIco3.png");
		}

		.classify-tab.router-link-active {
			background-image: url("../assets/footIco4.png");
		}
		
		.vip-tab {
			width: 0.57rem;
			height: 0.75rem;
			background-image: url("../assets/footIco5.png");
		}
		
		.vip-tab.router-link-active {
			background-image: url("../assets/footIco6.png");
		}

		.buy-car {
			width: 0.57rem;
			height: 0.75rem;
			background-image: url("../assets/footIco9.jpg");
		}

		.buy-car.router-link-active {
			background-image: url("../assets/footIco10.jpg");
		}

		.mine-tab {
			width: 0.51rem;
			height: 0.73rem;
			background-image: url("../assets/footIco7.png");
		}

		.mine-tab.router-link-active {
			background-image: url("../assets/footIco8.png");
		}


	}
</style>
