<template>
	<div  class="home">
		
		<div class="cdkeyCon">
			<div class="cdkeyPic"><img src="../assets/cdKey.png" alt=""></div>
			<div class="cdkeyInp">
				<input v-model="cdKey" type="text" placeholder="输入您的卡密">
			</div>
			<div @click="exchange" class="cdkeyBtn"></div>
		</div>
		
		
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "home",
		components: {
			FootTab
		},
		data() {
			return {
				"title": "",
				"cdKey":''
			}
		},
		created() {

		},
		mounted() {
			var that = this;
			
		},
		methods: {
			exchange() {
				var that = this;
				// 获取文创产品
				that.http.exChange({
					'cdkey':that.cdKey
				}).then((res) => {
					this.$dialog.toast({
						mes: res.data.msg,
						timeout: 1500
					});
				});
			}
		},



	};
</script>

<style scoped lang="scss">
	.home {
		background-color: #fff;
		min-height: 100vh;
		// padding-top: 0.17rem;
		padding-bottom: 1.46rem;
		text-align: left;
		
		.cdkeyCon{
			position: relative;
			
			.cdkeyInp{
				position: absolute;
				top: 4rem;
				left: 0.75rem;
				width: 6rem;
				height: 1rem;
				
				input{
					width: 100%;
					height: 100%;
					display: block;
					text-align: center;
					font-size: 0.36rem;
					color: #58401c;
					background-color: #f6f0e7;
					border-radius: 0.2rem;
					letter-spacing: 0.02rem;
				}
			}
			
			.cdkeyBtn{
				position: absolute;
				top: 5.4rem;
				left: 50%;
				transform: translateX(-50%);
				width: 2.7rem;
				height: 0.86rem;
				border-radius: 100px;
				// background-image: linear-gradient(90deg, 
				// 		rgba(234, 205, 159, 0.71) 0%, 
				// 		rgba(210, 170, 110, 0.71) 84%), 
				// 	linear-gradient(
				// 		#ffffff, 
				// 		#ffffff);
			}
		}
		
	}
</style>
