<template>
  <div class="home" style="background: red">
    <div class="book-info">
      <div class="book-word">
        <div class="book-item-wrap">
          <div class="book-item-main">
            <img :src="list.cover" />
          </div>
        </div>
        <div class="book-introduction">
          <div class="book-name">{{ list.bookName }}</div>
          <div class="book-writer">{{ list.authorName }}</div>
          <div class="book-scal-wrap">
            <div class="book-scal">
              <div class="book-tag">
                <p>{{ list.subcateName }}</p>
                <p>{{ list.finish === 1 ? "完结" : "连载" }}</p>
                <p>{{ list.wordNum }}字</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="book-datail" v-if="list">
        <div class="datail-item">
          <div class="datail-num">{{ list.favorCount }}</div>
          <div class="datail-word">收藏数</div>
        </div>
        <div class="datail-item">
          <div class="datail-num">{{ list.clickCount }}</div>
          <div class="datail-word">点击数</div>
        </div>
        <div class="datail-item">
          <div class="datail-num">{{ list.readCount }}</div>
          <div class="datail-word">阅读数</div>
        </div>
        <div class="datail-item">
          <div class="datail-num">{{ list.commentCount }}</div>
          <div class="datail-word">评论数</div>
        </div>
      </div>
      <div class="book-introduction-wrap" v-if="list">
        {{ list.description }}
      </div>
      <div class="discuss-wrap" v-if="listB.length != 0">
        <div v-for="(item, index) in listB" :key="index">
          <a class="discuss-line" @click="toReadClick(item,index)">
            <span class="book-chapters">{{ item.chapterName }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
   
<script>
// import { info, bookCatalog } from "@/api/info.js";
import axios from "axios";
export default {
  data() {
    return {
      active: 0,
      infoObj: {},
      bookFreeInfo: {},
      map: {},
      category: {},
      list: "",
      listB: "",
    };
  },
  created() {
    this.infoPost(this.$route.query.id);
    this.bookCatalogPost(this.$route.query.id);
    if (this.$route.query.active != undefined) {
      this.active = Number(this.$route.query.active);
    }
  },
  methods: {
    infoPost(id) {
      this.http.getBookDetail({
				"bookId": id,
			}).then((res) => {
        this.list = res.data.data;
			});
    },
    //章节
    bookCatalogPost(i) {
      this.http.getChapterList({
				"bookId": i,
			}).then((res) => {
        console.log(res.data,"www");
        if(res.data.status == 1){
          localStorage.setItem('booklist', JSON.stringify(res.data.data));
          this.listB = res.data.data;
        }else if(res.data.status == 99){
          this.$message({
						showClose: true,
						message: res.data.msg,
						type: 'warning'
					});
          setTimeout(() => {
            this.$router.push({
              path: "/mine",
            });
          }, 1500);
        }else if(res.data.status == 100){
          this.$message({
						showClose: true,
						message: res.data.msg,
						type: 'warning'
					});
          setTimeout(() => {
            this.$router.push({
              path: "/vip",
            });
          }, 1500);
        }
       
			});
    },
    toReadClick(i,index){
      localStorage.setItem('bookindex', JSON.stringify(index));
      this.$router.push({
					path: "/toRead",
					query: {
						id: i.chapterId
					}
				});
    },
  },
};
</script>
<style scoped lang="scss">
.title {
  color: #333;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.van-tabs__wrap {
  height: 40px !important;
}
.van-tabs__nav--card {
  border-right: 1px solid;
  border: none;
  margin: 0;
  height: 40px;
}
.van-tab--card {
  border: none;
  color: #666;
  border-bottom: 3px solid #00000000;
}
.van-tab--card.van-tab--active {
  color: #e2bd54;
  background: #fff;
  border-bottom: 2.5px solid #e2bd54 !important;
}
.van-tab--card:last-child {
  border-left: 1px solid #f2f2f2;
}
.book-info {
  background-color: #fff;
}
.book-info .book-word {
  padding: 12px 0 7.5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.book-info .book-word .book-item-wrap {
  position: relative;
  padding-left: 15%;
}
.book-info .book-word .book-item-wrap .book-item-main {
  position: relative;
  width: 66px;
  height: 90px;
  border-radius: 1.2px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
}
.book-info .book-word .book-item-wrap .book-item-main img {
  width: 100%;
  min-height: 100%;
  display: block;
}
.book-info .book-word .book-item-wrap .book-item-main .book-free {
  position: absolute;
  bottom: 1.5px;
  right: 1.5px;
  width: 15px;
  height: 15px;
  background-position: -408.5px -133px;
}
.book-info .book-word .book-item-wrap .book-num {
  width: 24px;
  height: 16.5px;
  position: absolute;
  top: 3px;
  left: -6px;
}
.book-info .book-word .book-introduction {
  flex: 1;
  -webkit-flex: 1;
  padding: 1.2px 51px 1.2px 15px;
  text-align: left;
}
.book-info .book-word .book-introduction .book-name {
  font-size: 18px;
  color: #323232;
}
.book-info .book-word .book-introduction .book-writer {
  font-size: 12px;
  padding: 5px 0;
  color: #969696;
}
.book-info .book-word .book-introduction .book-scal-wrap {
  position: relative;
  min-height: 36px;
}
.book-info .book-word .book-introduction .book-scal-wrap .book-scal {
  display: block;
  width: 200%;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  position: absolute;
  left: 0;
  top: 0;
}
.book-info
  .book-word
  .book-introduction
  .book-scal-wrap
  .book-scal
  .renew-time {
  color: #ff6432;
  font-size: 19.5px;
  padding-bottom: 7.5px;
}
.book-info
  .book-word
  .book-introduction
  .book-scal-wrap
  .book-scal
  .renew-time
  .timing-wrap {
  color: #969696;
}
.book-info
  .book-word
  .book-introduction
  .book-scal-wrap
  .book-scal
  .renew-time
  .timing-wrap
  i {
  font-style: normal;
}
.book-info .book-word .book-introduction .book-scal-wrap .book-scal .book-tag {
  padding-top: 7.2px;
  overflow: hidden;
}
.book-info
  .book-word
  .book-introduction
  .book-scal-wrap
  .book-scal
  .book-tag
  p {
  float: left;
  padding: 4px 6px;
  border-radius: 6px;
  font-size: 18px;
  margin-right: 12px;
  border: 1px solid #eebf64;
  color: #eebf64;
}
.book-info .book-datail {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  padding: 7.5px 0; /* 0.625rem to px */
  border-bottom: 1px solid #f5f5f5;
}

.book-info .book-datail .datail-item {
  flex: 1;
  -webkit-flex: 1;
}

.book-info .book-datail .datail-item .datail-num {
  font-size: 12px; /* 1rem to px */
  line-height: 14.4px; /* 1.2rem to px */
  color: #eebf64;
}

.book-info .book-datail .datail-item .datail-word {
  font-size: 10px; /* 0.83333rem to px */
  color: #969696;
}
.book-info .book-introduction-wrap {
  color: #969696;
  padding: 6px 14.4px; /* 0.5rem and 1.2rem to px */
  font-size: 10px; /* 0.83333rem to px */
  line-height: 19.2px; /* 1.6rem to px */
  text-align: left;
}

.read-history {
  background-color: #fff;
  margin-top: 12px; /* 1rem to px */
}

.read-history .history-line {
  padding: 0 14.4px; /* 1.2rem to px */
  line-height: 30px; /* 2.5rem to px */
  height: 30px; /* 2.5rem to px */
  border-bottom: 1px solid #f5f5f5;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  font-size: 12px;
}

.read-history .history-line .history-tit {
  color: #333;
}

.read-history .history-line .history-num {
  flex: 1;
  -webkit-flex: 1;
  padding: 0 6px; /* 0.5rem to px */
  color: #ff6432;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.read-history .history-line .history-time {
  color: #333;
  font-size: 10px; /* 0.83333rem to px */
  padding-left: 6px; /* 0.5rem to px */
}

.read-history .history-line .history-time.special {
  color: #ff6432;
}

.read-history .recommend {
  padding: 0 14.4px; /* 1.2rem to px */
  display: block;
  line-height: 30px; /* 2.5rem to px */
  height: 30px; /* 2.5rem to px */
  color: #333;
}
.recommend {
  padding: 0 14.4px; /* 1.2rem to px */
  display: block;
  line-height: 30px; /* 2.5rem to px */
  height: 30px; /* 2.5rem to px */
  color: #333;
  font-size: 12px;
  text-align: left;
}
.foot-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgba(83, 83, 83, 0.8);
  width: 100%;
  height: 34.4px; /* 2.91667rem to px */
  line-height: 34.4px; /* 2.91667rem to px */
  text-align: center;
}

.foot-wrap .foot-btn {
  flex: 1;
  -webkit-flex: 1;
  color: #fff;
  font-size: 12px; /* 1rem to px */
}

.foot-wrap .foot-read {
  width: 129px; /* 10.75rem to px */
  position: relative;
}

.foot-wrap .foot-read a {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: block;
  height: 44.5px; /* 3.70833rem to px */
  line-height: 40.8px; /* 3.4rem to px */
  font-size: 15px; /* 1.25rem to px */
  //   background: url(../../../assets/img/book/read.png) no-repeat center center;
  background-size: 100%;
  -webkit-background-size: 100%;
  color: #fff;
}
.book-info .read-record .read-last {
  padding: 0 8px; /* converted from 0.7rem */
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 40.5px; /* converted from 3.375rem */
  line-height: 40.5px; /* converted from 3.375rem */
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  font-size: 14px; /* converted from 1.16667rem */
  border-bottom: 1px solid #f0f0f0;
  justify-content: space-between;
}

.book-info .read-record .read-last .read-tit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.book-info .read-record .read-last .read-tit i {
  font-style: normal;
  margin-right: 4.8px; /* converted from 0.4rem */
  color: #ff6432;
}

.book-info .read-record .read-last .read-tit i.read-new {
  color: #eebf64;
}

.book-info .read-record .read-last .arrowheadRight {
  width: 6px; /* converted from 0.5rem */
  padding-right: 10.5px; /* converted from 0.875rem */
}

.book-info .read-record .read-last .arrowheadRight img {
  width: 100%;
  display: block;
}

.discuss-wrap {
  width: 96%;
  background-color: #fff;
  margin: 6px auto; /* converted from 0.5rem */
}

.discuss-wrap .discuss-line {
  height: 36px; /* converted from 3rem */
  line-height: 36px; /* converted from 3rem */
  border-bottom: 1px solid #f5f5f5;
  padding: 0 9px 0 7.5px; /* converted from 0.75rem and 0.625rem */
  font-size: 9px; /* converted from 0.75rem */
  color: #666;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px; /* converted from 1.16667rem */
  justify-content: space-between;
}

.discuss-wrap .discuss-line .charge-frame {
  width: 28px; /* converted from 2rem */
  text-align: center;
  font-size: 12px; /* converted from 1rem */
  background-color: #f68c6d;
  color: #000;
  line-height: 11px; /* adjusted for better fit */
  padding: 3.6px; /* converted from 0.3rem */
  color: #fff;
}
</style>
