<template>
	<div  class="home">
		<div v-show="loginType" class="eachLogin">
			<div class="title">密码登录</div>
			
			<input v-model="pwPhone" class="input1" type="text" placeholder="手机号/用户名/邮箱">
			
			<div style="position: relative;">
				<input v-model="password" class="input2" :type="inputType" placeholder="密码">
				<span @click="changeInputType" class="isShowPassword" :class="isShowPassWord ? 'showPassword' : 'hidePassword'"></span>
			</div>
			
			<div @click="passwordLoin" class="loginBtn">登录</div>
			
		</div>
		<div v-show="!loginType" class="eachLogin">
			<div class="title">验证码登录</div>
			
			<div class="titleMes">未注册的手机号验证通过后将自动注册</div>
			
			<div class="phoneLogin">
				<span>+86</span>
				<input class="input3" type="text" placeholder="请输入手机号">
			</div>
			
			<div class="loginBtn">获取验证码</div>
			
		</div>
		<div v-show="isLiantong" class="loginBot clearfix">
			<span @click="changeLogin" class="fl">{{loginName}}</span>
			<!-- <span v-show="loginType" class="fr">忘记密码</span> -->
		</div>
		<FootTab></FootTab>
	</div>
</template>

<script>
	import FootTab from "@/components/FootTab.vue";
	export default {
		name: "login",
		components: {
			FootTab
		},
		data() {
			return {
				"title":"登录",
				loginType:true,
				loginName:"验证码登录",
				"pwPhone":"",
				"password":"",
				"userMes":{
					"user_token":""
				},
				"isLiantong":true,
				
				"isShowPassWord":false,
				"inputType":"password"
			}
		},
		created() {
			// if (window.location.href.indexOf('unicom') != -1) {
			// 	this.isLiantong = true;
			// }
		},
		mounted() {
			localStorage.setItem("userMes",JSON.stringify(this.userMes));
		},
		methods: {
			changeLogin() {
				
				this.http.getChannelInfo({}).then((res) => {
					if (res.data.status == 1) {
						localStorage.setItem("channelId",res.data.data.id);
						if (res.data.data.loginUrl) {
							window.location.href = res.data.data.loginUrl;
						} else{
							this.$router.push({name: "codeLogin"})
						}
						
					} else{
						that.$message({
							showClose: true,
							message: res.data.msg,
							type: 'error'
						});
					}
				});
				
				
				// window.location.href = "http://account.bol.wo.cn/login?clientId=hfgobookstore&redirectUrl=" + encodeURIComponent(window.location.origin) + "%2Fpage%2FssoLogin";
				// window.location.href = "http://account.bol.wo.cn/login?clientId=hfgobookstore&redirectUrl=" + encodeURIComponent(window.location.origin) + "%2Fpage%2Fhome";
			},
			passwordLoin() {
				if (this.pwPhone == "" || this.password == "") {
					
					this.$message({
						showClose: true,
						message: "手机号码或密码不能为空",
						type: 'error'
					});
					return;
				}
				// if(!(/^1[3456789]\d{9}$/.test(this.pwPhone))){ 
				//     this.$message.error('请输入正确的手机号码');
				//     return;
				// }
				
				
				
				// 密码登录
				this.http.passwordLogin({
					"phone":this.pwPhone,
					"password":this.password
				}).then((res) => {
					if (res.data.status == 1) {
						localStorage.setItem("userMes",JSON.stringify(res.data.data));
						
						if (sessionStorage.getItem("loginReturn")) {
							window.location.href = sessionStorage.getItem("loginReturn");
						} else{
							this.$router.push({name: "home"})
						}
					}else{
						this.$message({
							showClose: true,
							message: res.data.msg,
							type: 'error'
						});
					}
				});
			},
			
			changeInputType(){
				this.isShowPassWord = !this.isShowPassWord;
				if (this.inputType == 'password') {
					this.inputType = 'text';
				}else{
					this.inputType = 'password'
				}
			}
		},



	};
</script>

<style scoped lang="scss">
	.home {
		min-height: 100vh;
		background-color: #fff;
		padding: 0.76rem 0.72rem 0;
		.title{
			font-size: 0.46rem;
			text-align: left;
			color: #2b2a2a;
			font-weight: bold;
		}
		
		.input1{
			margin-top: 0.7rem;
			display: block;
			width: 100%;
			height: 1.12rem;
			font-size: 0.37rem;
			color: #2b2a2a;
			text-indent: 0.18rem;
			border-bottom: 1PX solid #bcbcbc;
		}
		
		.input2{
			margin-top: 0.26rem;
			display: block;
			width: 100%;
			height: 1.12rem;
			font-size: 0.37rem;
			color: #2b2a2a;
			text-indent: 0.18rem;
			border-bottom: 1PX solid #bcbcbc;
		}
		
		.phoneLogin{
			position: relative;
			span{
				position: absolute;
				top: 0.02rem;
				left: 0;
				height: 1.12rem;
				line-height: 1.12rem;
				font-size: 0.3rem;
				opacity: 0.6;
				color: #2b2a2a;
			}
			.input3{
				margin-top: 1.38rem;
				display: block;
				width: 100%;
				height: 1.12rem;
				font-size: 0.37rem;
				color: #2b2a2a;
				text-indent: 1.35rem;
				border-bottom: 1PX solid #bcbcbc;
			}
		}
		
		
		.loginBtn{
			margin-top: 0.7rem;
			width: 5.84rem;
			height: 0.96rem;
			border-radius: 100px;
			line-height: 0.96rem;
			font-size: 0.36rem;
			color: #fff;
			// background-image: linear-gradient(-90deg, 
			// 		rgba(220, 181, 124, 0.5) 0%, 
			// 		rgba(230, 199, 154, 0.5) 52%, 
			// 		rgba(239, 217, 183, 0.5) 100%), 
			// 	linear-gradient(
			// 		#ffffff, 
			// 		#ffffff);
			background-color: #f1b65c;
			letter-spacing: 2px;
		}
		
		.loginBot{
			margin-top: 0.6rem;
			font-size: 0.3rem;
			color: #2b2a2a;
		}
		
		.titleMes{
			text-align: left;
			font-size: 0.3rem;
			line-height: 0.3rem;
			color: #2b2a2a;
			opacity: 0.6;
			margin-top: 0.42rem;
		}
		
		
		.isShowPassword{
			position: absolute;
			width: 0.48rem;
			height: 0.48rem;
			right: 0.2rem;
			top: 50%;
			transform: translateY(-50%);
			background-size: cover;
		}
		
		.isShowPassword.showPassword{
			background-image: url(../assets/showPassWord.png);
		}
		.isShowPassword.hidePassword{
			background-image: url(../assets/hidePassWord.png);
		}
		
	}
</style>
